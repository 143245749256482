<template>
  <div v-if="LabelTitle">
    <div class="fr_pageTitle fixed-title">{{ LabelTitle }}</div>
    <Search v-bind:tableItems="tableItems" v-bind:tableColumns="tableColumns"
      v-on:searchConditionChanged="searchConditionChanged" />
    <div class="fr_dataView">
      <vue-good-table :columns="tableColumns" :rows="tableItemsSearched" v-on:row-click="rowClicked" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: false
      }" :pagination-options="{ enabled: true }" :sort-options="{ enabled: false }"
        styleClass="fr_dataTable fr_dataTable-FactoryInventory">
        <template #table-row="{ row, column }">
          <td @click="startEditing(row, column.field)">
            <template v-if="isEditing(row, column.field)">
              <input ref="editInput" @blur="stopEditing" @keyup.enter="stopEditing" />
            </template>
            <template v-else>
              {{ row[column.field] }}
            </template>
          </td>
        </template>
      </vue-good-table>
    </div>
    <Loading :loading="loading" />
  </div>
  <div v-else>
    <Search v-bind:tableItems="tableItems" v-bind:tableColumns="tableColumns"
      v-on:searchConditionChanged="searchConditionChanged" />
    <vue-good-table ref="table" :columns="tableColumns" :rows="tableItemsSearched" v-on:row-click="rowClicked"
      v-on:change="checkbokUpdated" :select-options="{
        enabled: enableSelect,
        selectOnCheckboxOnly: useCheckbox,
      }" :pagination-options="{ enabled: true }" :sort-options="{ enabled: false }"
      styleClass="fr_dataTable fr_dataTable-FactoryInventory" />
    <Loading :loading="loading" />
  </div>

  <!-- Modal -->
  <div class="modal fade" id="trace" tabindex="-1" aria-labelledby="trace" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <div class="fr_sectionTitle">{{ LabelTrace }}</div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <Trace v-bind:dataRow="dataRow" />
        </div>
        <div class="modal-footer">
          <div class="fr_primaryButton">
            <button type="button" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Db from "@/db";
import ColumnInfo from "@/components/ColumnInfo.js";
import Search from "@/components/Search.vue";
import { getLocale } from '@/store.js';
import Trace from '@/components/Trace.vue';
import Util from '@/util';
import Loading from "./Loading.vue";

export default {
  name: 'ViewDbData',
  components: {
    Search,
    Trace,
    Loading,
  },
  props: { route: String, update: Boolean, enableSelect: Boolean, useCheckbox: Boolean },
  emits: ['onLogout', 'dataUpdated', 'dataLoaded', 'rowClicked', 'checkbokUpdated'],

  //----------------------------------------------------------------------
  data() {
    return {
      routeName: null,

      tableFactory: [],
      tableItems: [],
      tableItemsSearched: [],
      tableColumns: [],

      tableThread: [],
      tableCloth: [],
      tableProduct: [],
      tableColor: [],

      selected: [],
      editingRow: null,
      editingField: null,
      loading: false,

      sortBy: [{ key: 'id', order: 'desc' }],

      dataRow: null,
    };
  },

  //----------------------------------------------------------------------
  computed: {
    LabelTitle() {
      if (this.$route.name == 'viewFactoryInventory') return getLocale(this.$store.state, 220001);
      if (this.$route.name == 'viewRecProcure') return getLocale(this.$store.state, 20001);
      if (this.$route.name == 'viewRecProcess') return getLocale(this.$store.state, 30001);
      if (this.$route.name == 'viewRecDetails') return getLocale(this.$store.state, 200001);
      if (this.$route.name == 'viewRecMove') return getLocale(this.$store.state, 210001);
      if (this.$route.name == 'viewRecPaymentCollect') return getLocale(this.$store.state, 40001);
      if (this.$route.name == 'viewRecSales') return getLocale(this.$store.state, 250001);

      if (this.$route.name == 'viewSupplierThread') return getLocale(this.$store.state, 120001);
      if (this.$route.name == 'viewSupplierCloth') return getLocale(this.$store.state, 130001);
      if (this.$route.name == 'viewCustomer') return getLocale(this.$store.state, 240001);

      if (this.$route.name == 'viewFactory') return getLocale(this.$store.state, 230001);
      if (this.$route.name == 'viewThread') return getLocale(this.$store.state, 160001);
      if (this.$route.name == 'viewCloth') return getLocale(this.$store.state, 170001);
      if (this.$route.name == 'viewColor') return getLocale(this.$store.state, 260001);
      if (this.$route.name == 'viewProduct') return getLocale(this.$store.state, 180001);
    },
    LabelTrace: function () { return getLocale(this.$store.state, 11501); },
  },

  //----------------------------------------------------------------------
  async created() {
    await this.loadDb();
    this.routeName = this.$route.name;
  },
  async beforeUpdate() {
    //await this.loadDb();
    //this.routeName = this.$route.name;
  },
  async updated() {
    //await this.loadDb();
    //this.routeName = this.$route.name;
  },

  //----------------------------------------------------------------------
  watch: {
    route: function () {
      this.routeName = this.route;
      this.loadData();
    },
    routeName: function () {
      this.loadData();
    },
    update: function () {
      this.loadData();
      this.$emit("dataUpdated");
    },
  },

  //----------------------------------------------------------------------
  methods: {
    //----------------------------------------------------------------------
    loadData() {
      const columnInfo = ColumnInfo.getColumnInfo(this.getTableName(this.routeName));
      this.tableColumns = this.getTableColumns(columnInfo);

      Db.loadData(this.$store.getters.dbDataFilename, this.getTableName(this.routeName)).then((data) => {
        const temp = JSON.parse(JSON.stringify(Db.arrayToObj(data)));
        const items = Object.keys(temp).map(function (key) {
          return temp[key];
          //idでソート
        }).sort(function (a, b) {
          return (a.id > b.id) ? -1 : 1;
        });
        this.$emit("dataLoaded", items);

        if (this.routeName == 'viewFactoryInventory') this.tableItems = this.getTableItemsOfFactoryInventory(items);
        if (this.routeName == 'viewRecProcure') this.tableItems = this.getTableItemsOfRecProcure(items);
        if (this.routeName == 'viewRecProcess') this.tableItems = this.getTableItemsOfRecProcess(items);
        if (this.routeName == 'viewRecDetails') this.tableItems = this.getTableItemsOfRecDetails(items);
        if (this.routeName == 'viewRecMove') this.tableItems = this.getTableItemsOfRecMove(items, 'any');
        if (this.routeName == 'viewRecSales') this.tableItems = this.getTableItemsOfRecSales(items);
        if (this.routeName == 'viewRecPaymentCollect') this.tableItems = this.getTableItemsOfRecPaymentCollect(items);
        if (this.routeName == 'viewSupplierThread') this.tableItems = this.getTableItems(items);
        if (this.routeName == 'viewSupplierCloth') this.tableItems = this.getTableItems(items);
        if (this.routeName == 'viewCustomer') this.tableItems = this.getTableItems(items);
        if (this.routeName == 'viewThread') this.tableItems = this.getTableItems(items);
        if (this.routeName == 'viewCloth') this.tableItems = this.getTableItemsOfClothOrProduct(items);
        if (this.routeName == 'viewProduct') this.tableItems = this.getTableItemsOfClothOrProduct(items);
        if (this.routeName == 'viewFactory') this.tableItems = this.getTableItemsOfFactory(items);
        if (this.routeName == 'viewColor') this.tableItems = this.getTableItemsOfColor(items);
        if (this.routeName == 'procureThread') this.tableItems = this.getTableItemsOfRecProcure(items);
        if (this.routeName == 'procureCloth') this.tableItems = this.getTableItemsOfRecProcure(items);
        if (this.routeName == 'startProductionCloth') this.tableItems = this.getTableItemsOfRecProcess(items);
        if (this.routeName == 'startProductionProduct') this.tableItems = this.getTableItemsOfRecProcess(items);
        if (this.routeName == 'startDyeingCloth') this.tableItems = this.getTableItemsOfRecProcess(items);
        if (this.routeName == 'finishProductionCloth') this.tableItems = this.getTableItemsOfRecProcess(items);
        if (this.routeName == 'finishProductionProduct') this.tableItems = this.getTableItemsOfRecProcess(items);
        if (this.routeName == 'finishDyeingCloth') this.tableItems = this.getTableItemsOfRecProcess(items);
        if (this.routeName == 'moveThread') this.tableItems = this.getTableItemsOfRecMove(items, 'Thread');
        if (this.routeName == 'moveCloth') this.tableItems = this.getTableItemsOfRecMove(items, 'Cloth');
        if (this.routeName == 'moveProduct') this.tableItems = this.getTableItemsOfRecMove(items, 'Product');
        if (this.routeName == 'batchProductionCloth') this.tableItems = this.getTableItemsOfRecProcess(items);
        if (this.routeName == 'salesProduct') this.tableItems = this.getTableItemsOfRecSales(items);
        if (this.routeName == 'paymentThread') this.tableItems = this.getTableItemsOfRecPaymentCollect(items);
        if (this.routeName == 'paymentCloth') this.tableItems = this.getTableItemsOfRecPaymentCollect(items);
        if (this.routeName == 'collectReceivable') this.tableItems = this.getTableItemsOfRecPaymentCollect(items);
        if (this.routeName == 'registerSupplierThread') this.tableItems = this.getTableItems(items);
        if (this.routeName == 'registerSupplierCloth') this.tableItems = this.getTableItems(items);
        if (this.routeName == 'registerCustomer') this.tableItems = this.getTableItems(items);
        if (this.routeName == 'registerThread') this.tableItems = this.getTableItems(items);
        if (this.routeName == 'registerCloth') this.tableItems = this.getTableItemsOfClothOrProduct(items);
        if (this.routeName == 'registerProduct') this.tableItems = this.getTableItemsOfClothOrProduct(items);
        if (this.routeName == 'registerFactory') this.tableItems = this.getTableItemsOfFactory(items);
        if (this.routeName == 'registerColor') this.tableItems = this.getTableItemsOfColor(items);
        if (this.routeName == 'trace') this.tableItems = this.getTableItemsOfFactoryInventory(items);
        this.tableItems = JSON.parse(JSON.stringify(this.tableItems));
        this.tableItemsSearched = this.tableItems;
      });
    },

    //----------------------------------------------------------------------
    getTableName(routeName) {
      if (routeName == 'viewFactoryInventory') return 'FactoryInventory';
      if (routeName == 'viewRecProcure') return 'RecProcure';
      if (routeName == 'viewRecProcess') return 'RecProcess';
      if (routeName == 'viewRecDetails') return 'RecDetails';
      if (routeName == 'viewRecPaymentCollect') return 'RecPaymentCollect';
      if (routeName == 'viewRecMove') return 'RecMove';
      if (routeName == 'viewRecSales') return 'RecSales';
      if (routeName == 'viewSupplierThread') return 'SupplierThread';
      if (routeName == 'viewSupplierCloth') return 'SupplierCloth';
      if (routeName == 'viewCustomer') return 'Customer';
      if (routeName == 'viewFactory') return 'Factory';
      if (routeName == 'viewThread') return 'Thread';
      if (routeName == 'viewCloth') return 'Cloth';
      if (routeName == 'viewProduct') return 'Product';
      if (routeName == 'viewColor') return 'Color';
      if (routeName == 'procureThread') return 'RecProcure';
      if (routeName == 'procureCloth') return 'RecProcure';
      if (routeName == 'startProductionCloth') return 'RecProcess';
      if (routeName == 'startProductionProduct') return 'RecProcess';
      if (routeName == 'startDyeingCloth') return 'RecProcess';
      if (routeName == 'finishProductionCloth') return 'RecProcess';
      if (routeName == 'finishProductionProduct') return 'RecProcess';
      if (routeName == 'finishDyeingCloth') return 'RecProcess';
      if (routeName == 'batchProductionCloth') return 'RecProcess';
      if (routeName == 'paymentThread') return 'RecPaymentCollect';
      if (routeName == 'paymentCloth') return 'RecPaymentCollect';
      if (routeName == 'collectReceivable') return 'RecPaymentCollect';
      if (routeName == 'moveThread') return 'RecMove';
      if (routeName == 'moveCloth') return 'RecMove';
      if (routeName == 'moveProduct') return 'RecMove';
      if (routeName == 'salesProduct') return 'RecSales';
      if (routeName == 'registerSupplierThread') return 'SupplierThread';
      if (routeName == 'registerSupplierCloth') return 'SupplierCloth';
      if (routeName == 'registerCustomer') return 'Customer';
      if (routeName == 'registerFactory') return 'Factory';
      if (routeName == 'registerThread') return 'Thread';
      if (routeName == 'registerCloth') return 'Cloth';
      if (routeName == 'registerProduct') return 'Product';
      if (routeName == 'registerColor') return 'Color';
      if (routeName == 'trace') return 'FactoryInventory';
    },

    //----------------------------------------------------------------------
    getTableColumns(columnInfo) {
      const tableColumns = [];
      Object.keys(columnInfo).forEach((key) => {
        tableColumns.push({
          label: getLocale(this.$store.state, columnInfo[key].labelId),
          field: columnInfo[key].item,
          search: columnInfo[key].search,
        });
      });
      return tableColumns;
    },

    //----------------------------------------------------------------------
    getTableItemsOfFactoryInventory(items) {
      const tableItems = [];
      Object.keys(items).forEach((key) => {
        const data = items[key];
        data.factoryName = this.tableFactory.find((r) => { return r.id == items[key].factoryId; }).name;

        if (items[key].itemType == 'Thread') {
          data.itemType = getLocale(this.$store.state, 160001);
          data.itemName = this.tableThread.find((r) => { return r.id == items[key].itemId; }).name;
        }
        else if (items[key].itemType == 'Cloth') {
          data.itemType = getLocale(this.$store.state, 170001);
          data.itemName = this.tableCloth.find((r) => { return r.id == items[key].itemId; }).name;
        }
        else if (items[key].itemType == 'Dyeing') {
          data.itemType = getLocale(this.$store.state, 11611);
          data.itemName = this.tableCloth.find((r) => { return r.id == items[key].itemId; }).name;
        }
        else if (items[key].itemType == 'Product') {
          data.itemType = getLocale(this.$store.state, 180001);
          data.itemName = this.tableProduct.find((r) => { return r.id == items[key].itemId; }).name;
        }

        data.unit = items[key].isLen ? 'm' : 'kg';

        const color = this.tableColor.find((r) => { return r.id == items[key].colorId; });
        if (color) data.colorName = color.name;

        const status = items[key].status;
        if (status == 'Procured') data.statusStr = getLocale(this.$store.state, 11509);
        if (status == 'Processing') data.statusStr = getLocale(this.$store.state, 11510);
        if (status == 'Finished') data.statusStr = getLocale(this.$store.state, 11511);

        tableItems.push(data);
      });
      return tableItems;
    },

    //----------------------------------------------------------------------
    getTableItemsOfRecProcure(items) {
      const tableItems = [];
      Object.keys(items).forEach((key) => {
        const data = items[key];

        if (items[key].itemType == 'Thread') {
          data.supplierName = this.tableSupplierThread.find((r) => { return r.id == items[key].supplierId; }).name;
          data.itemType = getLocale(this.$store.state, 160001);
          data.itemName = this.tableThread.find((r) => { return r.id == items[key].itemId; }).name;
        }
        else if (items[key].itemType == 'Cloth') {
          data.supplierName = this.tableSupplierCloth.find((r) => { return r.id == items[key].supplierId; }).name;
          data.itemType = getLocale(this.$store.state, 170001);
          data.itemName = this.tableCloth.find((r) => { return r.id == items[key].itemId; }).name;
        }
        data.deliveryName = this.tableFactory.find((r) => { return r.id == items[key].deliveryId; }).name;
        data.unit = items[key].isLen ? 'm' : 'kg';
        const amountBdSimple = Util.getAmountBdSimple(JSON.parse(data.amountBd));
        let amountBd = amountBdSimple.count + getLocale(this.$store.state, 10423) + ' (' +
          amountBdSimple.amount + ')';
        if (!items[key].isLen) {
          amountBd += ' / (' + Util.getAmountBdSimpleKori(JSON.parse(data.amountBd)).amount + ' ' +
            getLocale(this.$store.state, 10218) + ')';
        }
        data.amountBd = amountBd;
        tableItems.push(data);
      });
      return tableItems;
    },

    //----------------------------------------------------------------------
    getTableItemsOfRecProcess(items) {
      const tableItems = [];
      Object.keys(items).forEach((key) => {
        const data = items[key];

        data.factoryName = this.tableFactory.find((r) => { return r.id == items[key].factoryId; }).name;
        if (items[key].itemType == 'Cloth') {
          data.itemType = getLocale(this.$store.state, 170001);
          data.itemName = this.tableCloth.find((r) => { return r.id == items[key].itemId; }).name;
        }
        if (items[key].itemType == 'Product') {
          data.itemType = getLocale(this.$store.state, 180001);
          data.itemName = this.tableProduct.find((r) => { return r.id == items[key].itemId; }).name;
        }
        else if (items[key].itemType == 'Dyeing') {
          data.itemType = getLocale(this.$store.state, 11611);
          data.itemName = this.tableCloth.find((r) => { return r.id == items[key].itemId; }).name;
        }
        data.unit = items[key].isLen ? 'm' : 'kg';
        const amountBd = JSON.parse(data.amountBd);
        if ('pre' in amountBd) {
          const amountBdSimple = Util.getAmountBdSimple(amountBd.pre);
          data.amountPre = amountBdSimple.count + getLocale(this.$store.state, 10423) + ' (' +
            amountBdSimple.amount + ')';
        }
        if ('post' in amountBd) {
          const amountBdSimple = Util.getAmountBdSimple(amountBd.post);
          data.amountPost = amountBdSimple.count + getLocale(this.$store.state, 10423) + ' (' +
            amountBdSimple.amount + ')';
        }

        const color = this.tableColor.find((r) => { return r.id == items[key].colorId; });
        if (color) data.colorName = color.name;

        tableItems.push(data);
      });
      return tableItems;
    },

    //----------------------------------------------------------------------
    getTableItemsOfRecDetails(items) {
      const tableItems = [];
      Object.keys(items).forEach((key) => {
        const data = items[key];

        if (items[key].itemType == 'Cloth') {
          data.itemType = getLocale(this.$store.state, 170001);
          data.itemName = this.tableThread.find((r) => { return r.id == items[key].itemId; }).name;
        }
        if (items[key].itemType == 'Product') {
          data.itemType = getLocale(this.$store.state, 180001);
          data.itemName = this.tableCloth.find((r) => { return r.id == items[key].itemId; }).name;
        }

        tableItems.push(data);
      });
      return tableItems;
    },

    //----------------------------------------------------------------------
    getTableItemsOfRecMove(items, type) {
      const tableItems = [];
      Object.keys(items).forEach((key) => {
        if ((items[key].itemType == 'Thread' && type == 'Thread') ||
          (items[key].itemType == 'Cloth' && type == 'Cloth') ||
          (items[key].itemType == 'Product' && type == 'Product') ||
          type == 'any') {

          const data = items[key];
          //console.log(items)

          data.srcType = this.getTypeOfFactory(this.tableFactory.find((r) => { return r.id == items[key].srcId; }).type);
          data.srcName = this.tableFactory.find((r) => { return r.id == items[key].srcId; }).name;
          data.dstType = this.getTypeOfFactory(this.tableFactory.find((r) => { return r.id == items[key].dstId; }).type);
          data.dstName = this.tableFactory.find((r) => { return r.id == items[key].dstId; }).name;

          if (items[key].itemType == 'Thread') {
            data.itemType = getLocale(this.$store.state, 160001);
            data.itemName = this.tableThread.find((r) => { return r.id == items[key].itemId; }).name;
          }
          if (items[key].itemType == 'Cloth') {
            data.itemType = getLocale(this.$store.state, 170001);
            data.itemName = this.tableCloth.find((r) => { return r.id == items[key].itemId; }).name;
          }
          if (items[key].itemType == 'Product') {
            data.itemType = getLocale(this.$store.state, 180001);
            data.itemName = this.tableProduct.find((r) => { return r.id == items[key].itemId; }).name;
          }
          data.unit = items[key].isLen ? 'm' : 'kg';
          const amountBdSimple = Util.getAmountBdSimple(JSON.parse(data.amountBd));
          data.amountBd = amountBdSimple.count + getLocale(this.$store.state, 10423) + ' (' +
            amountBdSimple.amount + ')';

          tableItems.push(data);
        }
      });
      return tableItems;
    },

    //----------------------------------------------------------------------
    getTableItemsOfRecSales(items) {
      const tableItems = [];
      Object.keys(items).forEach((key) => {
        const data = items[key];

        data.customerName = this.tableCustomer.find((r) => { return r.id == items[key].customerId; }).name;
        data.itemName = this.tableProduct.find((r) => { return r.id == items[key].itemId; }).name;

        tableItems.push(data);
      });
      return tableItems;
    },

    //----------------------------------------------------------------------
    getTableItemsOfRecPaymentCollect(items) {
      const tableItems = [];
      Object.keys(items).forEach((key) => {
        const data = items[key];

        if (items[key].dealType == 'SupplierThread') {
          data.dealType = getLocale(this.$store.state, 10011);
          data.dealerName = this.tableSupplierThread.find((r) => { return r.id == items[key].dealerId; }).name;
        }
        else if (items[key].dealType == 'SupplierCloth') {
          data.dealType = getLocale(this.$store.state, 10012);
          data.dealerName = this.tableSupplierCloth.find((r) => { return r.id == items[key].dealerId; }).name;
        }
        else if (items[key].dealType == 'Customer') {
          data.dealType = getLocale(this.$store.state, 10013);
          data.dealerName = this.tableCustomer.find((r) => { return r.id == items[key].dealerId; }).name;
        }

        tableItems.push(data);
      });
      return tableItems;
    },

    //----------------------------------------------------------------------
    getTableItemsOfClothOrProduct(items) {
      const tableItems = [];
      Object.keys(items).forEach((key) => {
        const data = items[key];

        if (items[key].colorId != '') {
          const colorId = JSON.parse(items[key].colorId);
          data.colorNames = '';
          for (let cid of colorId) {
            data.colorNames += this.tableColor.find(({ id }) => id == cid).name + ', ';
          }
          if (data.colorNames.length > 0) data.colorNames = data.colorNames.slice(0, -2);
        }

        tableItems.push(data);
      });
      return tableItems;
    },

    //----------------------------------------------------------------------
    getTableItemsOfFactory(items) {
      const tableItems = [];
      Object.keys(items).forEach((key) => {
        const data = items[key];
        data.type = this.getTypeOfFactory(items[key].type);
        tableItems.push(data);
      });
      return tableItems;
    },

    //----------------------------------------------------------------------
    getTableItemsOfColor(items) {
      const tableItems = [];

      Object.keys(items).forEach((key) => {
        const data = items[key];
        tableItems.push(data);
      });

      return tableItems;
    },

    //----------------------------------------------------------------------
    getTableItems(items) {
      const tableItems = [];
      Object.keys(items).forEach((key) => {
        const data = items[key];
        tableItems.push(data);
      });
      return tableItems;
    },

    //----------------------------------------------------------------------
    getTypeOfFactory(type) {
      if (type == 'HQ') return getLocale(this.$store.state, 1101);
      if (type == 'Cloth') return getLocale(this.$store.state, 170001);
      if (type == 'Dyeing') return getLocale(this.$store.state, 11611);
      if (type == 'Product') return getLocale(this.$store.state, 180001);
      return '';
    },

    //----------------------------------------------------------------------
    async loadDb() {
      this.loading = true;
      this.tableFactory = await Db.loadData(this.$store.getters.dbDataFilename, 'Factory');
      this.tableThread = await Db.loadData(this.$store.getters.dbDataFilename, 'Thread');
      this.tableCloth = await Db.loadData(this.$store.getters.dbDataFilename, 'Cloth');
      this.tableProduct = await Db.loadData(this.$store.getters.dbDataFilename, 'Product');
      this.tableColor = await Db.loadData(this.$store.getters.dbDataFilename, 'Color');
      this.tableSupplierThread = await Db.loadData(this.$store.getters.dbDataFilename, 'SupplierThread');
      this.tableSupplierCloth = await Db.loadData(this.$store.getters.dbDataFilename, 'SupplierCloth');
      this.tableCustomer = await Db.loadData(this.$store.getters.dbDataFilename, 'Customer');
      this.loading = false;
    },

    //----------------------------------------------------------------------
    searchConditionChanged(condition) {
      this.updateTableItems(condition);
    },

    updateTableItems(condition) {
      const dateBegin = Date.parse(condition.DateBegin);
      const dateEnd = Date.parse(condition.DateEnd);

      this.tableItemsSearched = this.tableItems.filter((item) => {
        if (!isNaN(dateBegin) && Date.parse(item.date) < dateBegin) return false;
        if (!isNaN(dateEnd) && Date.parse(item.date) > dateEnd) return false;

        const keys = Object.keys(condition.LBSelected);
        for (let i = 0; i < keys.length; i++) {
          if (condition.LBSelected[keys[i]] == '') continue;
          if (item[keys[i]] != condition.LBSelected[keys[i]]) return false;
        }
        return true;
      });
      this.tableItemsSearched = JSON.parse(JSON.stringify(this.tableItemsSearched));
    },
    //----------------------------------------------------------------------
    rowClicked(params) {
      //if (this.routeName == 'viewFactoryInventory' || this.routeName == 'trace') {
      if (this.routeName == 'trace') {
        this.dataRow = params.row;
        const modal = new bootstrap.Modal(document.getElementById('trace'));
        modal.show();
      }

      this.$emit("rowClicked", params.row);
    },

    checkbokUpdated() {
      this.$emit("checkbokUpdated", this.$refs['table'].selectedRows);
    },

    startEditing(row, field) {
      return;
      if (field !== "amount") return; // amount のみ編集可能
      this.editingRow = row;
      this.editingField = field;
      this.$nextTick(() => {
        this.$refs.editInput?.[0]?.focus();
      });
    },
    stopEditing() {
      this.editingRow = null;
      this.editingField = null;
    },
    isEditing(row, field) {
      return this.editingRow === row && this.editingField === field;
    }
  },
}

</script>
