//=====================================================================
// API to access DB
//

export default {
  execMove,
};

import Db from "@/db.js";
import Log from "@/components/db/Log.js";

//----------------------------------------------------------------------
async function execMove(data) {
  const lastId = await Db.getLastId(data.dbDataFilename);
  const recMoveId = lastId + 1;
  const factoryInventoryId = recMoveId + 1;
  const logId = factoryInventoryId + 1;

  // 移動記録に追加
  const responseRecMove = await addRecMove(data.dbDataFilename, {
    ...data,
    id: recMoveId,
  });
  if (responseRecMove.result != "ok") {
    alert("fail\n" + responseRecMove.error.errorInfo);
    return false;
  }

  // 工場在庫に追加
  const responseAddFactoryInventory = await addFactoryInventory(
    data.dbDataFilename,
    {
      ...data,
      id: factoryInventoryId,
    }
  );
  if (responseAddFactoryInventory.result != "ok") {
    alert("fail\n" + responseAddFactoryInventory.error.errorInfo);
    return false;
  }

  // 工場在庫を減少
  const responseReduceFactoryInventory = await reduceFactoryInventory(
    data.dbDataFilename,
    {
      ...data,
    }
  );
  if (responseReduceFactoryInventory.result != "ok") {
    alert("fail\n" + responseReduceFactoryInventory.error.errorInfo);
    return false;
  }

  // ログを追加
  const responseAddLog = await Log.addLog(data.dbDataFilename, {
    id: logId,
    datetime: Date().toString(),
    pid: recMoveId,
    type: data.type,
  });
  if (responseAddLog.result != "ok") {
    alert("fail\n" + responseAddLog.error.errorInfo);
    return false;
  }

  return true;
}

//----------------------------------------------------------------------
async function addRecMove(dbDataFilename, data) {
  return Db.addData(dbDataFilename, "RecMove", [
    {
      id: data.id,
      srcId: data.src.id,
      dstId: data.dst.id,
      itemType: data.itemType,
      itemId: data.item.itemId,
      isLen: data.item.isLen,
      amount: data.amount,
      amountBd: data.amountBd.replaceAll('"', '""'),
      date: data.date,
    },
  ]);
}

//----------------------------------------------------------------------
async function addFactoryInventory(dbDataFilename, data) {
  return Db.addData(dbDataFilename, "FactoryInventory", [
    {
      id: data.id,
      factoryId: data.dst.id,
      itemType: data.itemType,
      itemId: data.item.itemId,
      isLen: data.item.isLen,
      amount: data.amount,
      status: data.item.status,
      recordId: data.item.recordId,
      date: data.date,
      colorId: data.item.colorId,
    },
  ]);
}

//----------------------------------------------------------------------
async function reduceFactoryInventory(dbDataFilename, data) {
  return Db.updateData(dbDataFilename, "FactoryInventory", [
    {
      id: data.item.id,
      amount: data.item.amount - data.amount,
    },
  ]);
}
