<template>
  <div v-if="!lenDisable">
    <input type="radio" name="unit" id="length" value="length" v-model="unit" @change="onChanged">
    <label for="length">{{ LabelLength }}</label>
  </div>
  <div v-if="!kgDisable">
    <input type="radio" name="unit" id="weight" value="weight" v-model="unit" @change="onChanged">
    <label for="weight">{{ LabelWeight }}</label>
  </div>
  <div v-if="!koriDisable">
    <input type="radio" name="unit" id="weightKori" value="weightKori" v-model="unit" @change="onChanged">
    <label for="weightKori">{{ LabelWeightKori }}</label>
  </div>
</template>

<script>
import { getLocale } from '../store.js';

export default {
  name: 'unitInput',
  props: {
    lenDisable: Boolean,
    kgDisable: Boolean,
    koriDisable: Boolean,
    unitDefault: String,
  },
  emits: ['unitChanged'],

  data() {
    return {
      unit: "length",
    };
  },

  computed: {
    LabelLength: function () {
      return getLocale(this.$store.state, 10205);
    },
    LabelWeight: function () {
      return getLocale(this.$store.state, 10206);
    },
    LabelWeightKori: function () {
      return getLocale(this.$store.state, 10218);
    },
  },

  mounted() {
    this.$emit("unitChanged", this.unit);
  },

  watch: {
    unitDefault: function () {
      if (this.unitDefault == "length") {
        this.unit = "length";
      } else if (this.unitDefault == "weight") {
        this.unit = "weight";
      } else if (this.unitDefault == "weightKori") {
        this.unit = "weightKori";
      }
    },
  },

  methods: {
    onChanged() {
      this.$emit("unitChanged", this.unit);
    },
  },
}

</script>