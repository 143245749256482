<template>
  <div v-for="(col, key) in columnInfo" :key="key">
    <div class="fr_inputSingle" v-if="col.item != 'colorNames'">
      <input type="text" :placeholder="col.label" v-model="newItem[col.item]" v-on:change="clothProductUpdated">
    </div>
    <div v-else>
      <SelectColor v-bind:checkedColor="checkedColor" v-on:valueUpdated="colorUpdated" />
    </div>
  </div>
</template>    

<script>
import SelectColor from '@/components/SelectColor.vue';

export default {
  name: 'RegisterClothProduct',
  props: ['columnInfo', 'checkedItem'],
  emits: ['clothProductUpdated'],
  components: {
    SelectColor,
  },

  //----------------------------------------------------------------------
  data() {
    return {
      newItem: {},
      checkedColor: null,
      colorChecked: [],
    };
  },

  //----------------------------------------------------------------------
  watch: {
    checkedItem: function () {
      this.newItem.id = this.checkedItem.id;
      this.newItem.name = this.checkedItem.name;
      this.checkedColor = JSON.parse(this.checkedItem.colorId);
    },
  },

  //----------------------------------------------------------------------
  methods: {
    //----------------------------------------------------------------------
    colorUpdated(checkedColor) {
      this.colorChecked = checkedColor;
      this.clothProductUpdated();
    },

    clothProductUpdated() {
      this.newItem.colorId = JSON.stringify(this.colorChecked);
      this.$emit("clothProductUpdated", this.newItem);
    },
  },
}


</script>