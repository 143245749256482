export default {
  getColumnInfo,
  setTypeFactoryInventory,
  setTypeRecProcess,
  setTypeRecDetails,
};

function getColumnInfo(tableName) {
  if (tableName == 'FactoryInventory') {
    return [
      { item: 'id', labelId: 220002 },
      { item: 'date', labelId: 220012 },
      { item: 'factoryName', labelId: 220004, search: true },
      { item: 'itemType', labelId: 220005, search: true },
      { item: 'itemName', labelId: 220007, search: true },
      { item: 'amount', labelId: 220105 },
      { item: 'unit', labelId: 220107 },
      { item: 'statusStr', labelId: 220010, search: true },
      { item: 'colorName', labelId: 260003, search: true },
    ];
  }

  if (tableName == 'RecProcure') {
    return [
      { item: 'date', labelId: 20013 },
      { item: 'supplierName', labelId: 20004, search: true },
      { item: 'itemType', labelId: 20005, search: true },
      { item: 'itemName', labelId: 20007, search: true },
      { item: 'pricePerUnit', labelId: 20008 },
      { item: 'amount', labelId: 220105 },
      { item: 'unit', labelId: 220107 },
      { item: 'amountBd', labelId: 20014 },
      { item: 'deliveryName', labelId: 20016, search: true },
    ];
  }

  if (tableName == 'RecProcess') {
    return [
      { item: 'id', labelId: 30002 },
      { item: 'date', labelId: 30013 },
      { item: 'factoryName', labelId: 30004, search: true },
      { item: 'itemType', labelId: 30005, search: true },
      { item: 'itemName', labelId: 30007, search: true },
      { item: 'materialAmount', labelId: 30018 },
      { item: 'finishAmount', labelId: 30019 },
      { item: 'unit', labelId: 220107 },
      { item: 'cost', labelId: 30012 },
      { item: 'amountPre', labelId: 30016 },
      { item: 'amountPost', labelId: 30017 },
      { item: 'colorName', labelId: 30015, search: true },
    ];
  }

  if (tableName == 'RecDetails') {
    return [
      { item: 'date', labelId: 200011 },
      { item: 'processId', labelId: 200003 },
      { item: 'itemType', labelId: 200004, search: true },
      { item: 'itemName', labelId: 200006, search: true },
      { item: 'materialAmount', labelId: 200012 },
      { item: 'loss', labelId: 200009 },
      { item: 'inventoryId', labelId: 200010 },
    ];
  }

  if (tableName == 'RecMove') {
    return [
      { item: 'date', labelId: 210012 },
      { item: 'srcType', labelId: 210015, search: true },
      { item: 'srcName', labelId: 210004, search: true },
      { item: 'dstType', labelId: 210016, search: true },
      { item: 'dstName', labelId: 210006, search: true },
      { item: 'itemType', labelId: 210007, search: true },
      { item: 'itemName', labelId: 210009, search: true },
      { item: 'amount', labelId: 210014 },
      { item: 'unit', labelId: 220107 },
      { item: 'amountBd', labelId: 210013 },
    ];
  }

  if (tableName == 'RecSales') {
    return [
      { item: 'date', labelId: 250012 },
      { item: 'customerName', labelId: 250004, search: true },
      { item: 'itemName', labelId: 250006, search: true },
      { item: 'pricePerUnit', labelId: 250007 },
      { item: 'amount', labelId: 250013 },
      { item: 'memo', labelId: 250011 },
    ];
  }

  if (tableName == 'RecPaymentCollect') {
    return [
      { item: 'date', labelId: 40007 },
      { item: 'dealType', labelId: 40003, search: true },
      { item: 'dealerName', labelId: 40005, search: true },
      { item: 'amount', labelId: 40006 },
    ];
  }

  if (tableName == 'Thread') {
    return [
      { item: 'id', labelId: 160002, type: 'number' },
      { item: 'name', labelId: 160003, search: true },
    ];
  }

  if (tableName == 'Cloth') {
    return [
      { item: 'id', labelId: 170002, type: 'number' },
      { item: 'name', labelId: 170003 },
      { item: 'colorNames', labelId: 170004, search: true, acceptEmpty: true },
    ];
  }

  if (tableName == 'Color') {
    return [
      { item: 'color', labelId: 260003, search: true },
      { item: 'number', labelId: 260004, search: true, type: 'number' },
      { item: 'name', labelId: 260005, search: true },
      { item: 'bt', labelId: 260006, search: true },
      { item: 'info1', labelId: 260007, search: true, acceptEmpty: true },
      { item: 'info2', labelId: 260008, search: true, acceptEmpty: true },
      { item: 'info3', labelId: 260009, search: true, acceptEmpty: true }
    ];
  }

  if (tableName == 'Product') {
    return [
      { item: 'id', labelId: 180002, type: 'number', type: 'number' },
      { item: 'name', labelId: 180003, search: true },
      { item: 'colorNames', labelId: 180004, search: true, acceptEmpty: true },
    ];
  }

  if (tableName == 'Factory') {
    return [
      { item: 'id', labelId: 230002, type: 'number' },
      { item: 'type', labelId: 230003, search: true },
      { item: 'name', labelId: 230004, search: true },
    ];
  }

  if (tableName == 'SupplierThread') {
    return [
      { item: 'id', labelId: 120002, type: 'number' },
      { item: 'name', labelId: 120003, search: true },
      { item: 'amount', labelId: 120004, type: 'number' },
    ];
  }

  if (tableName == 'SupplierCloth') {
    return [
      { item: 'id', labelId: 130002, type: 'number' },
      { item: 'name', labelId: 130003, search: true },
      { item: 'amount', labelId: 130004, type: 'number' },
    ];
  }

  if (tableName == 'Customer') {
    return [
      { item: 'id', labelId: 240002, type: 'number' },
      { item: 'name', labelId: 240003, search: true },
      { item: 'amount', labelId: 240004, type: 'number' },
    ];
  }

  if (tableName == 'users') {
    return [
      { item: 'mail', labelId: 270002 },
      { item: 'database', labelId: 270005, search: true },
      { item: 'authority', labelId: 270006, search: true },
      { item: 'expiration', labelId: 270007 },
    ];
  }
}


function setTypeFactoryInventory(table) {
  table.forEach((item) => {
    item.id = Number(item.id);
    item.factoryId = Number(item.factoryId);
    item.itemId = Number(item.itemId);
    item.amount = Number(item.amount);
    item.recordId = Number(item.recordId);
    item.colorId = item.colorId ? Number(item.colorId) : item.colorId;
  });
  return table;
}

function setTypeRecProcess(table) {
  table.forEach((item) => {
    item.id = Number(item.id);
    item.factoryId = Number(item.factoryId);
    item.itemId = Number(item.itemId);
    item.materialAmount = Number(item.materialAmount);
    item.finishAmount = Number(item.finishAmount);
    item.isLen = Number(item.isLen);
    item.cost = Number(item.cost);
    item.colorId = item.colorId ? Number(item.colorId) : item.colorId;
  });
  return table;
}

function setTypeRecDetails(table) {
  table.forEach((item) => {
    item.id = Number(item.id);
    item.processId = Number(item.processId);
    item.itemId = Number(item.itemId);
    item.materialAmount = Number(item.materialAmount);
    item.inventoryId = Number(item.inventoryId);
    item.loss = Number(item.loss);
  });
  return table;
}
