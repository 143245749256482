<template>
  <div v-if="loading" class="modal">
    <div class="modal-content">
      <span class="loader"></span>
      <slot>{{ LabelProcessing }}</slot>
    </div>
  </div>
</template>

<script>
import { getLocale } from "@/store.js";

export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    LabelProcessing() { return getLocale(this.$store.state, 10318); },
  }
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transition: pointer-events 0.3s;
}

/* v-if による表示中のみ操作をブロック */
.modal[style*="display: flex"] {
  pointer-events: auto;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.loader {
  width: 40px;
  height: 40px;
  border: 5px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
