<template>
  <div class="fr_trace">
    <div class="fr_pageTitle">{{ LabelTitle }}</div>

    <div class="fr_historyTable fr_scrollableContent">
      <div class="fr_sectionTitle">{{ LabelRecord }}</div>
      <ViewDbData v-bind:enableSelect="false"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getLocale } from '../store.js';
import ViewDbData from '@/components/ViewDbData.vue';

export default {
  name: 'trace',
  components: {
    ViewDbData,
  },

  //----------------------------------------------------------------------
  computed: mapState({
    LabelTitle: state => getLocale(state, 11501),
    LabelRecord: state => getLocale(state, 220001),
  }),
}

</script>
