<template>
  <div class="fr_procduction">
    <div class="fr_pageTitle">{{ LabelTitle }}</div>
    <div class="fr_entryForm fr_scrollableContent">
      <div>
        <div class="fr_sectionTitle">{{ LabelForm }}</div>
        <ul class="fr_selectList">
          <li class="fr_datapickerWrap">
            <VueDatePicker v-model="date" format="yyyy/MM/dd" :enable-time-picker="false" light />
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBFactorySelected" v-on:change="OnLBFactoryChanged" return-object>
                <option v-for="factory in LBFactory" :value="factory" :key="factory.id">
                  {{ factory.name }}
                </option>
                <option :value="LBFactorySelected" disabled hidden>
                  {{ LabelSelectFactory }}
                </option>
              </select>
            </div>
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBItemSelected" v-on:change="OnLBItemChanged" return-object>
                <option v-for="item in LBItem" :value="item">
                  {{ item.name }}
                </option>
                <option :value="LBItemSelected" disabled hidden>
                  {{ LabelSelectItem }}
                </option>
              </select>
            </div>
          </li>
        </ul>

        <p>{{ LabelSelectAndAdd }}</p>
        <ul class="fr_selectList">
          <li>
            <div class="fr_selecterWrap">
              <select id="SelectInventory" v-model="LBInventorySelected" return-object>
                <option v-for="inventory in LBInventory" :value="inventory">
                  {{ inventory.name }}
                </option>
                <option :value="LBInventorySelected" disabled hidden>
                  {{ LabelInventoryList }}
                </option>
              </select>
            </div>
          </li>
          <li>
            <div>
              <input type="number" :placeholder="LabelRatio" v-model="TBRatioPercent" />
            </div>
          </li>
          <li>
            <div>
              <input type="number" :placeholder="LabelLoss" v-model="TBLossPercent" />
            </div>
          </li>
        </ul>
        <div class="fr_primaryButton">
          <a v-on:click="
            addMaterial(LBInventorySelected, TBRatioPercent, TBLossPercent)
            ">{{ CBAdd }}</a>
        </div>

        <p>{{ LabelMaterialList }}</p>
        <ul>
          <li v-for="(item, index) in LBMaterial" :value="item.id" :key="index">
            {{ item.name }}, {{ item.ratio * 100 }}%, {{ item.loss * 100 }}%
            <button v-on:click="removeMaterial(index)">
              <img src="../assets/img/icons/icon-trash.svg" alt="" />
            </button>
          </li>
        </ul>

        <p>{{ LabelFillInAmount }}</p>
        <div class="fr_radioList">
          <UnitInput v-on:unitChanged="unitChanged" v-bind:koriDisable="true" />
        </div>
        <div class="fr_inputSingleSmall">
          <input type="number" :placeholder="LabelFillInAmount" v-model="TBAmount" />
        </div>
        <div class="fr_inputSingleSmall">
          <input type="number" :placeholder="LabelFillInNumber" v-model="TBNumber" />
        </div>
        <div class="fr_inputSingleSmall">
          <input type="number" :placeholder="LabelFillInCost" v-model="TBCost" />
        </div>
        <ul class="fr_selectList">
          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBDstSelected">
                <option v-for="dst in LBDst" :value="dst">{{ dst.name }}</option>
                <option :value="LBDstSelected" disabled hidden>{{ LabelSelectDst }}</option>
              </select>
            </div>
          </li>
        </ul>
        <div class="fr_primaryButton">
          <a v-on:click="execute">{{ CBExecute }}</a>
        </div>
      </div>
      <p><a v-if="enableTest" v-on:click="testInput1(routeName)">{{ test1 }}</a></p>
    </div>

    <div class="fr_historyTable">
      <div class="fr_sectionTitle">{{ LabelRecord }}</div>
      <ViewDbData v-bind:update="update" v-bind:enableSelect="false" v-bind:route="routeName"
        v-on:dataUpdated="dataUpdated" />
    </div>
  </div>
  <Loading :loading="loading" />
</template>

<script>
import Db from "@/db";
import Util from "@/util";
import { getLocale } from "@/store.js";
import UnitInput from "@/components/UnitInput.vue";
import ProcessItems from "@/components/db/ProcessItems.js";
import FinishItems from '@/components/db/FinishItems.js';
import MoveItems from '@/components/db/MoveItems.js';
import ViewDbData from "@/components/ViewDbData.vue";
import Loading from "./Loading.vue";

export default {
  name: "BatchProductionCloth",
  components: {
    UnitInput,
    ViewDbData,
    Loading,
  },
  props: { route: String },
  emits: ["onLogout"],

  data() {
    return {
      routeName: null,

      date: new Date(),
      LBFactory: [],
      LBFactorySelected: null,
      LBItem: [],
      LBItemSelected: null,
      LBInventory: [],
      LBInventorySelected: null,
      LBMaterial: [],
      LBDst: [],
      LBDstSelected: null,

      TBRatioPercent: null,
      TBLossPercent: null,
      TBAmount: null,
      TBNumber: null,
      TBCost: null,

      FactoryInventory: null,
      Material: null,

      update: false,
      loading: false,

      enableTest: process.env.NODE_ENV === 'development',
    };
  },

  computed: {
    LabelTitle() { return getLocale(this.$store.state, 10036); },
    LabelForm() { return getLocale(this.$store.state, 10419); },
    LabelSelectFactory() { return getLocale(this.$store.state, 10401); },
    LabelSelectItem() { return getLocale(this.$store.state, 10408); },
    LabelSelectAndAdd() { return getLocale(this.$store.state, 10402); },
    LabelInventoryList() { return getLocale(this.$store.state, 10403); },
    LabelRatio() { return getLocale(this.$store.state, 10404); },
    LabelLoss() { return getLocale(this.$store.state, 10405); },
    LabelMaterialList() { return getLocale(this.$store.state, 10407); },
    LabelFillInAmount() { return getLocale(this.$store.state, 10421); },
    LabelFillInCost() { return getLocale(this.$store.state, 10412); },
    LabelFillInNumber() { return getLocale(this.$store.state, 10422); },
    LabelSelectDst() { return getLocale(this.$store.state, 10802); },
    LabelRecord() { return getLocale(this.$store.state, 10420); },
    CBAdd() { return getLocale(this.$store.state, 10406); },
    CBExecute() { return getLocale(this.$store.state, 10807); },
    test1() { return 'Test1'; },
  },

  //----------------------------------------------------------------------
  async created() {
    this.routeName = this.route;
    this.loading = true;

    // 生地工場を取得
    const tableFactory = await Db.loadData(
      this.$store.getters.dbDataFilename,
      "Factory"
    );
    this.LBFactory = tableFactory.filter((item) => {
      return item.type == "Cloth";
    });
    this.LBFactorySelected = null;

    // 生産品リスト
    this.LBItem = await Db.loadData(
      this.$store.getters.dbDataFilename, "Cloth"
    );
    this.LBItemSelected = null;

    // 材料リスト
    this.Material = await Db.loadData(
      this.$store.getters.dbDataFilename, "Thread"
    );
    this.LBMaterial = [];

    // 工場在庫
    this.FactoryInventory = await Db.loadData(
      this.$store.getters.dbDataFilename,
      "FactoryInventory"
    );

    // 出荷先
    this.LBDst = await Db.loadData(
      this.$store.getters.dbDataFilename,
      "Factory"
    );

    this.loading = false;
  },
  updated() {
    this.routeName = this.route;
  },

  methods: {
    async execute() {
      // データチェック
      if (this.LBFactorySelected == null || this.LBItemSelected == null) {
        alert(getLocale(this.$store.state, 10220));
        return;
      }
      if (this.LBMaterial.length == 0) {
        alert(getLocale(this.$store.state, 10424));
        return;
      }
      if (this.TBAmount == null || Util.isNumeric(this.TBAmount) == false) {
        alert(getLocale(this.$store.state, 10421));
        return;
      }
      if (this.TBNumber == null || Util.isNumeric(this.TBNumber) == false) {
        alert(getLocale(this.$store.state, 10422));
        return;
      }
      if (this.TBCost == null || Util.isNumeric(this.TBCost) == false) {
        alert(getLocale(this.$store.state, 10425));
        return;
      }
      if (this.LBDstSelected == null) {
        alert(getLocale(this.$store.state, 10802));
        return;
      }

      this.loading = true;

      const retProcess = await ProcessItems.execProcess({
        dbDataFilename: this.$store.getters.dbDataFilename,
        factoryId: this.LBFactorySelected.id,
        itemType: "Cloth",
        itemId: this.LBItemSelected.id,
        isLen: this.unit == "length",
        material: this.LBMaterial,
        amount: this.TBAmount,
        amountBd: JSON.stringify({ pre: Util.calcBd(this.TBAmount, this.TBNumber) }),
        cost: this.TBCost,
        status: "Processing",
        date: Util.formatDate(new Date(this.date)),
        type: 'startProductionCloth',
      });
      if (retProcess.result != "ok") {
        this.loading = false;
        alert("Error in execProcess");
        return false;
      }

      const retFinish = await FinishItems.execFinish({
        dbDataFilename: this.$store.getters.dbDataFilename,
        processId: retProcess.recProcessId,
        amount: this.calcAmountFinished(),
        amountBd: Util.calcBd(this.calcAmountFinished(), this.TBNumber),
        date: Util.formatDate(new Date(this.date)),
        inventoryId: retProcess.factoryInventoryId,
        date: Util.formatDate(new Date(this.date)),
        type: 'finishProductionCloth',
      });
      if (!retFinish) {
        this.loading = false;
        alert("Error in execFinish");
        return false;
      }

      const retMove = await MoveItems.execMove({
        dbDataFilename: this.$store.getters.dbDataFilename,
        src: this.LBFactorySelected,
        dst: this.LBDstSelected,
        itemType: "Cloth",
        item: {
          id: retProcess.factoryInventoryId,
          amount: this.calcAmountFinished(),
          itemId: this.LBItemSelected.id,
          isLen: this.unit == "length",
          recordId: retProcess.recProcessId,
          status: "Finished",
        },
        amount: this.calcAmountFinished(),
        amountBd: JSON.stringify(Util.calcBd(this.calcAmountFinished(), this.TBNumber)),
        date: Util.formatDate(new Date(this.date)),
        type: 'moveCloth',
      });
      if (!retMove) {
        this.loading = false;
        alert("Error in execMove");
        return false;
      }

      this.loading = false;

      // 完了メッセージ表示
      alert(getLocale(this.$store.state, 10414));
      this.update = true;
      return true;
    },

    //----------------------------------------------------------------------
    OnLBFactoryChanged() {
      const inventory = this.FactoryInventory.filter((item) => {
        if (item.factoryId != this.LBFactorySelected.id) return false;
        if (item.itemType == "Thread") return true;
        return false;
      });
      inventory.forEach((item) => {
        item.name = this.Material.find(({ id }) => id == item.itemId).name;
        item.name += " " + item.amount;
        item.name += item.isLen ? "m" : "kg";
      });
      this.LBInventory = JSON.parse(JSON.stringify(inventory));
    },

    //----------------------------------------------------------------------
    OnLBItemChanged() {
      this.LBMaterial =
        this.LBItemSelected.material == null ||
          this.LBItemSelected.material == ""
          ? []
          : JSON.parse(decodeURIComponent(this.LBItemSelected.material));
      this.LBMaterial.forEach((mat) => {
        const item = this.FactoryInventory.find(
          ({ id }) => id == mat.inventoryId
        );
        mat.name = this.Material.find(({ id }) => id == item.itemId).name;
        mat.name += " " + item.amount;
        mat.name += item.isLen ? "m" : "kg";
      });
    },

    //----------------------------------------------------------------------
    addMaterial(item, ratioPercent, lossPercent) {
      if (this.LBFactorySelected == null || this.LBItemSelected == null ||
        this.LBInventorySelected == null || 
        this.TBRatioPercent == null || Util.isNumeric(this.TBRatioPercent) == false ||
        this.TBLossPercent == null || Util.isNumeric(this.TBLossPercent) == false) {
        alert(getLocale(this.$store.state, 10220));
        return;
      }

      this.LBMaterial.push({
        inventoryId: item.id,
        ratio: ratioPercent / 100.0,
        loss: lossPercent / 100.0,
        materialId: item.itemId,
        name: this.Material.find(({ id }) => id == item.itemId).name,
      });
    },

    removeMaterial(index) {
      this.LBMaterial.splice(index);
    },

    calcAmountFinished() {
      let amount = 0;
      this.LBMaterial.forEach((mat) => {
        amount += this.TBAmount * mat.ratio * (1 - mat.loss);
      });
      return amount;
    },

    //----------------------------------------------------------------------
    unitChanged(unit) {
      this.unit = unit;
    },

    dataUpdated() {
      this.update = false;
    },

    //----------------------------------------------------------------------
    async testInput1(routeName) {
      this.date = Util.formatDate(new Date());
      this.LBFactorySelected = this.LBFactory[0];
      this.OnLBFactoryChanged();
      this.LBItemSelected = this.LBItem[0];
      this.LBInventorySelected = this.LBInventory[0];
      this.TBRatioPercent = 100;
      this.TBLossPercent = 1;
      this.addMaterial(this.LBInventorySelected, this.TBRatioPercent, this.TBLossPercent);
      this.TBAmount = 8;
      this.TBNumber = 4;
      this.TBCost = 150;
      this.LBDstSelected = this.LBDst[0];
    }
  },
};

</script>