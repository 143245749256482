<template>
  <div v-for="(col, key) in columnInfo" :key="key">
    <div class="fr_inputSingle" v-if="col.item != 'type'">
      <input type="text" :placeholder="col.label" v-model="newItem[col.item]" v-on:change="factoryUpdated">
    </div>
    <div v-else>
      <ul class="fr_selectList">
        <li>
          <div class="fr_selecterWrap">
            <select v-model="newItem[col.item]" v-on:change="factoryUpdated">
              <option value="" disabled selected hidden >{{ LabelSelect }}</option>
              <option value="Cloth">{{ LabelCloth }}</option>
              <option value="Dyeing">{{ LabelDyeing }}</option>
              <option value="Product">{{ LabelProduct }}</option>
            </select>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getLocale } from '@/store.js';

export default {
  name: 'RegisterFactory',
  props: ['columnInfo'],
  emits: ['factoryUpdated'],

  //----------------------------------------------------------------------
  data() {
    return {
      newItem: {
        type: '',
      },
    };
  },

  computed: {
    LabelCloth: function () { return getLocale(this.$store.state, 170001); },
    LabelDyeing: function () { return getLocale(this.$store.state, 11611); },
    LabelProduct: function () { return getLocale(this.$store.state, 180001); },
    LabelSelect: function () { return getLocale(this.$store.state, 230003); },
  },

  methods: {
    //----------------------------------------------------------------------
    factoryUpdated() {
      this.$emit("factoryUpdated", this.newItem);
    },
  },
}
</script>
