<template>
  <div class="fr_inputCheckboxList">
    <span>{{ LabelColor }}</span>
    <div v-for="color in Color" :key="color.id">
      <input type="checkbox" :id="color.id" :disabled="color.id === 0" v-model="color.checked" v-on:change="emit" />
      <label :for="color.id">{{ color.name }}</label>
    </div>
  </div>
</template>

<script>
import Db from '@/db';
import { getLocale } from '@/store.js';

export default {
  name: 'SelectColor',
  props: ['checkedColor'],

  //----------------------------------------------------------------------
  data() {
    return {
      Color: [],
    };
  },

  computed: {
    LabelColor: function () { return getLocale(this.$store.state, 260001); },
  },

  //----------------------------------------------------------------------
  mounted() {
    Db.loadData(this.$store.getters.dbDataFilename, 'Color').then((data) => {
      this.Color = JSON.parse(JSON.stringify(Db.arrayToObj(data)));
      // idが0の項目は常にチェック
      for (let i in this.Color) {
        if (this.Color[i].id === 0) this.Color[i].checked = true;
      }
    });
  },

  //----------------------------------------------------------------------
  watch: {
    checkedColor: function () {
      for (let i in this.Color) {
        this.Color[i].checked = this.checkedColor.includes(this.Color[i].id);
      }
    },
  },

  //----------------------------------------------------------------------
  methods: {
    emit() {
      let checked = [];
      for (let c in this.Color) {
        if (this.Color[c].checked) checked.push(this.Color[c].id);
      }
      this.$emit("valueUpdated", checked);
    }
  },
}
</script>