//=====================================================================
// API to access DB
//

export default {
  execFinish,
};

import Db from "@/db";
import Log from "@/components/db/Log.js";

//----------------------------------------------------------------------
async function execFinish(data) {
  // 加工記録を更新
  const responseUpdateRecProcess = await updateRecProcess(data.dbDataFilename, {
    processId: data.processId,
    amount: data.amount,
    amountBd: data.amountBd,
    date: data.date,
  });
  if (responseUpdateRecProcess.result != "ok") {
    alert("fail\n" + responseUpdateRecProcess.error.errorInfo);
    return false;
  }

  // 工場在庫を更新
  const responseUpdateFactoryInventory = await updateFactoryInventory(
    data.dbDataFilename,
    {
      inventoryId: data.inventoryId,
      amount: data.amount,
      date: data.date,
    }
  );

  // ログを追加
  const responseAddLog = await Log.addLog(data.dbDataFilename, {
    id: (await Db.getLastId(data.dbDataFilename)) + 1,
    datetime: Date().toString(),
    pid: data.processId,
    type: data.type,
  });
  if (responseAddLog.result != "ok") {
    alert("fail\n" + responseAddLog.error.errorInfo);
    return false;
  }

  return true;
}

//----------------------------------------------------------------------
async function updateRecProcess(dbDataFilename, data) {
  const record = await Db.loadDataOne(
    dbDataFilename,
    "RecProcess",
    data.processId
  );
  const amountBd = JSON.parse(record[0].amountBd);
  amountBd.post = data.amountBd;

  return Db.updateData(dbDataFilename, "RecProcess", [
    {
      id: data.processId,
      finishAmount: data.amount,
      amountBd: JSON.stringify(amountBd).replaceAll('"', '"'),
      date: data.date,
    },
  ]);
}

//----------------------------------------------------------------------
async function updateFactoryInventory(dbDataFilename, data) {
  const item = await Db.loadDataOne(
    dbDataFilename,
    "FactoryInventory",
    data.inventoryId
  );

  return Db.updateData(dbDataFilename, "FactoryInventory", [
    {
      id: data.inventoryId,
      amount: data.amount,
      status: "Finished",
      date: data.date,
    },
  ]);
}
