//=====================================================================
// API to access DB
//

export default {
  execProcess,
};

import Db from "@/db.js";
import Log from "@/components/db/Log.js";

//----------------------------------------------------------------------
async function execProcess(data) {
  const lastId = await Db.getLastId(data.dbDataFilename);
  const recProcessId = lastId + 1;
  const recDetailId = recProcessId + 1;
  const factoryInventoryId = recDetailId + data.material.length;
  const logId = factoryInventoryId + 1;

  // 加工記録に追加
  const responseAddRecProcess = await addRecProcess(data.dbDataFilename, {
    ...data,
    id: recProcessId,
  });
  if (responseAddRecProcess.result != "ok") {
    alert("fail\n" + responseAddRecProcess.error.errorInfo);
    return false;
  }

  // 加工明細に追加
  const responseAddRecDetails = await addRecDetails(data.dbDataFilename, {
    ...data,
    id: recDetailId,
    processId: recProcessId,
  });
  if (responseAddRecDetails.result != "ok") {
    alert("fail\n" + responseAddRecDetails.error.errorInfo);
    return false;
  }

  // 工場在庫に追加
  const responseAddFactoryInventory = await addFactoryInventory(
    data.dbDataFilename,
    {
      ...data,
      id: factoryInventoryId,
      recordId: recProcessId,
    }
  );
  if (responseAddFactoryInventory.result != "ok") {
    alert("fail\n" + responseAddFactoryInventory.error.errorInfo);
    return false;
  }

  // 工場在庫の材料を減少
  const responseReduceMaterial = await reduceMaterial(data.dbDataFilename, {
    ...data,
  });
  if (responseReduceMaterial.result != "ok") {
    alert("fail\n" + responseReduceMaterial.error.errorInfo);
    return false;
  }

  // 材料を記録
  if (data.itemType == "Cloth" || data.itemType == "Product") {
    const responseUpdateMaterial = await updateMaterialOfClothOrProduct(
      data.dbDataFilename,
      data.itemType,
      data
    );
    if (responseUpdateMaterial.result != "ok") {
      alert("fail\n" + responseUpdateMaterial.error.errorInfo);
      return false;
    }
  }

  // ログを追加
  const responseAddLog = await Log.addLog(data.dbDataFilename, {
    id: logId,
    datetime: Date().toString(),
    pid: recProcessId,
    type: data.type,
  });
  if (responseAddLog.result != "ok") {
    alert("fail\n" + responseAddLog.error.errorInfo);
    return false;
  }

  return {
    result: "ok",
    recProcessId: recProcessId,
    recDetailId: recDetailId,
    factoryInventoryId: factoryInventoryId,
    logId: logId,
  };
}

//----------------------------------------------------------------------
async function addRecProcess(dbDataFilename, data) {
  return Db.addData(dbDataFilename, "RecProcess", [
    {
      id: data.id,
      factoryId: data.factoryId,
      itemType: data.itemType,
      itemId: data.itemId,
      isLen: data.isLen,
      materialAmount: data.amount,
      amountBd: data.amountBd.replaceAll('"', '""'),
      cost: data.cost,
      date: data.date,
      colorId: data.colorId,
    },
  ]);
}

//----------------------------------------------------------------------
async function addRecDetails(dbDataFilename, data) {
  const details = [];
  for (let i = 0; i < data.material.length; i++) {
    details.push({
      id: data.id + i,
      processId: data.processId,
      itemType: data.itemType,
      itemId: data.material[i]["materialId"],
      materialAmount: (data.amount * data.material[i]["ratio"]),
      loss: data.material[i]["loss"],
      inventoryId: data.material[i]["inventoryId"],
      date: data.date,
    });
  }

  return Db.addData(dbDataFilename, "RecDetails", details);
}

//----------------------------------------------------------------------
async function addFactoryInventory(dbDataFilename, data) {
  return Db.addData(dbDataFilename, "FactoryInventory", [
    {
      id: data.id,
      factoryId: data.factoryId,
      itemType: data.itemType,
      itemId: data.itemId,
      isLen: data.isLen,
      amount: data.amount,
      status: data.status,
      recordId: data.recordId,
      date: data.date,
      colorId: data.colorId,
    },
  ]);
}

//----------------------------------------------------------------------
async function reduceMaterial(dbDataFilename, data) {
  const details = [];
  for (let i = 0; i < data.material.length; i++) {
    const item = await Db.loadDataOne(
      dbDataFilename,
      "FactoryInventory",
      data.material[i]["inventoryId"]
    );
    item[0].amount -= (data.amount * data.material[i]["ratio"]);
    details.push({
      id: data.material[i]["inventoryId"],
      amount: item[0].amount,
    });
  }
  return Db.updateData(dbDataFilename, "FactoryInventory", details);
}

//----------------------------------------------------------------------
async function updateMaterialOfClothOrProduct(dbDataFilename, table, data) {
  return Db.updateData(dbDataFilename, table, [
    {
      id: data.itemId,
      material: encodeURIComponent(JSON.stringify(data.material)),
    },
  ]);
}
