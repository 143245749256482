<template>
  <div class="fr_register">
    <div class="fr_pageTitle">{{ LabelTitle }}</div>
    <div class="fr_entryForm fr_scrollableContent">
      <div>
        <div class="fr_sectionTitle">{{ LabelForm }}</div>
        <div v-if="routeName == 'registerCloth' || routeName == 'registerProduct'">
          <RegisterClothProduct v-bind:columnInfo="columnInfo" v-bind:checkedItem="checkedItem" v-on:clothProductUpdated="clothProductUpdated" />
        </div>
        <div v-else-if="routeName == 'registerFactory'">
          <RegisterFactory v-bind:columnInfo="columnInfo" v-on:factoryUpdated="factoryUpdated" />
        </div>
        <div v-else>
          <div v-for="(col, key) in columnInfo" :key="key">
            <div class="fr_inputSingle">
              <input type="text" :placeholder="col.label + (col.acceptEmpty ? LabelAcceptEmpty : '')" v-model="newItem[col.item]">
            </div>
          </div>
        </div>
        <div class="fr_primaryButton">
          <a v-on:click="addItem(newItem)">{{ LabelAdd }}</a>
        </div>
      </div>
    </div>

    <div class="fr_historyTable">
      <div class="fr_sectionTitle">{{ LabelRecord }}</div>
      <div>
        <ViewDbData v-bind:update="update" v-bind:enableSelect="true" v-bind:route="routeName" v-on:dataUpdated="dataUpdated" v-on:dataLoaded="dataLoaded" v-on:checkbokUpdated="checkbokUpdated" />
      </div>
      <div class="fr_buttonContainer">
        <div class="fr_dangerButton"><button v-on:click="remove()">{{ LabelRemove }}</button></div>
        <div class="fr_secondaryButton"><button v-on:click="loadCsv">{{ LabelLoadCsv }}</button></div>
      </div>
    </div>
  </div>

  <input style="display: none" ref="input" type="file" accept=".csv" @change="selectedFile()">
</template>

<script>
import Papa from 'papaparse';
import Db from '@/db';
import Util from "@/util";
import { getLocale } from '@/store.js';
import ColumnInfo from '@/components/ColumnInfo.js';
import RegisterClothProduct from '@/components/RegisterClothProduct.vue';
import RegisterFactory from '@/components/RegisterFactory.vue';
import ViewDbData from '@/components/ViewDbData.vue';

export default {
  name: 'RegisterItem',
  emits: ['onLogout'],
  components: {
    RegisterClothProduct,
    RegisterFactory,
    ViewDbData,
  },

  //----------------------------------------------------------------------
  data() {
    return {
      items: null,
      newItem: {},
      columnInfo: null,
      selected: [],
      routeName: null,
      update: false,

      checkedItem: null,
    };
  },

  //----------------------------------------------------------------------
  computed: {
    LabelTitle: function () {
      if (this.routeName == 'registerThread') return getLocale(this.$store.state, 10022);
      if (this.routeName == 'registerCloth') return getLocale(this.$store.state, 10023);
      if (this.routeName == 'registerColor') return getLocale(this.$store.state, 10024);
      if (this.routeName == 'registerProduct') return getLocale(this.$store.state, 10025);
      if (this.routeName == 'registerFactory') return getLocale(this.$store.state, 10021);
      if (this.routeName == 'registerSupplierThread') return getLocale(this.$store.state, 10018);
      if (this.routeName == 'registerSupplierCloth') return getLocale(this.$store.state, 10019);
      if (this.routeName == 'registerCustomer') return getLocale(this.$store.state, 10020);
    },
    LabelForm: function () { return getLocale(this.$store.state, 100009); },
    LabelID: function () { return getLocale(this.$store.state, 100006); },
    LabelName: function () { return getLocale(this.$store.state, 100001); },
    LabelAdd: function () { return getLocale(this.$store.state, 100002); },
    LabelRemove: function () { return getLocale(this.$store.state, 100003); },
    LabelRecord: function () { return getLocale(this.$store.state, 100010); },
    LabelLoadCsv: function () { return getLocale(this.$store.state, 100005); },
    LabelAcceptEmpty: function () { return getLocale(this.$store.state, 100014); },
  },

  //----------------------------------------------------------------------
  created() {
    this.routeName = this.$route.name;
  },
  updated() {
    this.routeName = this.$route.name;
  },

  //----------------------------------------------------------------------
  watch: {
    routeName: function () {
      this.columnInfo = this.getColumnInfo(this.getTableName(this.routeName));
      this.newItem = {};
    },
  },
  //----------------------------------------------------------------------
  methods: {
    //=====================================================================
    // API to access DB
    //
    async addToDb(newItem) {
      return (await Db.addData(this.$store.getters.dbDataFilename, this.getTableName(this.routeName), [newItem]));
    },

    async updateToDb(item) {
      return (await Db.updateData(this.$store.getters.dbDataFilename, this.getTableName(this.routeName), [item]));
    },

    async removeFromDb(id) {
      return (await Db.deleteData(this.$store.getters.dbDataFilename, this.getTableName(this.routeName), id));
    },


    //=====================================================================
    // local functions
    //
    async addItem(newItem) {
      // newItemのキーのどれかが未入力の場合は追加しない
      let inputError = false;
      let i;
      for (i = 0; i < this.columnInfo.length; i++) {
        if ('acceptEmpty' in this.columnInfo[i] == true) continue;
        if ('type' in this.columnInfo[i] && this.columnInfo[i].type == 'number' && 
            Util.isNumeric(newItem[this.columnInfo[i].item]) == false) {
          inputError = true; break;
        }
        if (!Object.keys(newItem).includes(this.columnInfo[i].item)) { inputError = true; break; }
        if (String(newItem[this.columnInfo[i].item]).trim() == "") { inputError = true; break; }
      }
      if (inputError) {
          alert(getLocale(this.$store.state, 100011) + ' ' + this.LabelID + ':' + this.columnInfo[i].label);
          return;
      }

      let response;
      if (this.items.find(({ id }) => id == newItem.id)) {
        // idが重複している場合は確認してから上書きする
        if (confirm(getLocale(this.$store.state, 100015)) == false) {
          return false;
        }
        response = await this.updateToDb(newItem);
      }
      else {
        response = await this.addToDb(newItem);
      }

      if (response.result == "ok") {
        this.items[newItem.id] = newItem;
        this.update = true;
        alert(getLocale(this.$store.state, 100012));
        return true;
      }
      else {
        alert("fail to add data\n" + response.error.errorInfo);
        return false;
      }
    },

    //----------------------------------------------------------------------
    async remove() {
      const id = [];
      this.selected.forEach((s) => { 
        if (this.routeName == 'registerColor' && s.id == 0) return;
        if (this.routeName == 'registerFactory' && s.id == 0) return;
        id.push({ id: s.id }); 
      });
      const response = await this.removeFromDb(id);
      if (response.result == "ok") {
        this.selected.forEach((s) => { delete this.items[s]; });
        this.update = true;
        alert(getLocale(this.$store.state, 100013));
      }
      else {
        alert("fail to delete data\n" + response.error.errorInfo);
        return false;
      }
    },

    //----------------------------------------------------------------------
    loadCsv: function () {
      this.$refs.input.click();
    },

    async selectedFile() {
      const file = this.$refs.input.files[0];
      if (!file) return;
      this.$refs.input.value = '';

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete: (result) => {
          console.log(result['data']);
          if (this.addData(result['data']) == false) {
            alert(getLocale(this.$store.state, 100008));
          }
        }
      });
    },

    addData: function (data) {
      for (let i = 0; i < data.length; i++) {
        if (this.addItem(data[i]) == false) return false;
      }
      return true;
    },

    //----------------------------------------------------------------------
    dataLoaded(items) {
      this.items = items;
    },

    clothProductUpdated(newItem) {
      this.newItem = newItem;
    },

    factoryUpdated(newItem) {
      this.newItem = newItem;
    },

    checkbokUpdated(items) {
      this.selected = items;
    },

    dataUpdated() {
      this.update = false;
    },

    //----------------------------------------------------------------------
    getTableName: function (routeName) {
      if (routeName == 'registerThread') return 'Thread';
      else if (routeName == 'registerCloth') return 'Cloth';
      else if (routeName == 'registerColor') return 'Color';
      else if (routeName == 'registerProduct') return 'Product';
      else if (routeName == 'registerFactory') return 'Factory';
      else if (routeName == 'registerSupplierThread') return 'SupplierThread';
      else if (routeName == 'registerSupplierCloth') return 'SupplierCloth';
      else if (routeName == 'registerCustomer') return 'Customer';
    },

    //----------------------------------------------------------------------
    getColumnInfo: function (tableName) {
      let columnInfo = ColumnInfo.getColumnInfo(tableName);
      columnInfo.forEach((c) => {
        c.label = getLocale(this.$store.state, c.labelId);
      });
      return columnInfo;
    },
  },
};

</script>
