<template>
  <div class="fr_inputtedContent fr_inputted-procure" v-for="(listData, listIndex) in ProcureList" :key="listIndex">
    <div>
      <ul>
        <li>
          <span>{{ LabelDate }}</span>
          <span>
            <VueDatePicker v-model="listData.date" format="yyyy/MM/dd" :enable-time-picker="false" readonly />
          </span>
        </li>
        <li>
          <span>{{ LabelItem }}</span>
          <input v-model="listData.itemName" readonly />
        </li>
        <li>
          <span>{{ LabelSupplier }}</span>
          <span><input v-model="listData.supplierName" readonly /></span>
        </li>
        <li>
          <span>{{ LabelDelivery }}</span>
          <span><input v-model="listData.deliveryName" readonly /></span>
        </li>
        <li>
          <span>{{ LabelPricePerUnit }}</span>
          <span>{{ listData.pricePerUnit }}</span>
        </li>
        <li>
          <span></span>
          <span>{{ listData.unitString }}</span>
        </li>
      </ul>
      <div>
        <p>{{ LabelDetails }}</p>
        <ul>
          <li v-for="(divData, divIndex) in listData.amountBd" :key="divIndex">{{ divData.toFixed(2) }}</li>
        </ul>
      </div>
    </div>
    <div>
      <button v-on:click="remove()"><img src="../assets/img/icons/icon-trash.svg" alt=""></button>
    </div>
  </div>
</template>

<style>
.font-class-name * {
  font-size: 12px;
}

.right-input input {
  text-align: right;
}
</style>

<script>
import { getLocale } from '@/store.js';

export default {
  name: 'ProcureTable',
  props: ['ProcureList', 'route'],
  emits: ['onLogout'],
  
  computed: {
    LabelDate() { return this.getLocale(this.route, 'LabelDate'); },
    LabelItem() { return this.getLocale(this.route, 'LabelItem'); },
    LabelSupplier() { return this.getLocale(this.route, 'LabelSupplier'); },
    LabelDelivery() { return this.getLocale(this.route, 'LabelDelivery'); },
    LabelPricePerUnit() { return this.getLocale(this.route, 'LabelPricePerUnit'); },
    LabelDetails() { return this.getLocale(this.route, 'LabelDetails'); },
  },

  //----------------------------------------------------------------------
  created() {
    this.routeName = this.route;
  },
  updated() {
    this.routeName = this.route;
  },

  //----------------------------------------------------------------------
  methods: {
    getLocale(routeName, label) {
      if (routeName == 'procureThread') {
        if (label == 'LabelDate') return getLocale(this.$store.state, 10216);
        if (label == 'LabelItem') return getLocale(this.$store.state, 160001);
        if (label == 'LabelDetails') return getLocale(this.$store.state, 10214);
      }
      else if (routeName == 'procureCloth') {
        if (label == 'LabelDate') return getLocale(this.$store.state, 10316);
        if (label == 'LabelItem') return getLocale(this.$store.state, 170001);
        if (label == 'LabelDetails') return getLocale(this.$store.state, 10314);
      }
      if (label == 'LabelSupplier') return getLocale(this.$store.state, 20004);
      if (label == 'LabelDelivery') return getLocale(this.$store.state, 20016);
      if (label == 'LabelPricePerUnit') return getLocale(this.$store.state, 20008);
    },
    remove(index) {
      this.ProcureList.splice(index, 1);
    },
  },
}

</script>