//------------------------------------------------------------------
export default {
  getTotal,
  getTotalBd,
  calcBd,
  formatDate,
  formattedDate,
  getUnitString,
  getAmountBdSimple,
  getAmountBdSimpleKori,
  isNumeric,
};

//----------------------------------------------------------------------
function getTotal(values, isKori = false) {
  let total = 0;
  values.forEach((r) => { total += Number(r) * (isKori ? 181.44: 1); });
  return total;
}

function getTotalBd(values, isKori = false) {
  let amount = [];
  values.forEach((r) => { amount.push(Number(r) * (isKori ? 181.44: 1)); });
  return amount;
}

//----------------------------------------------------------------------
function calcBd(amount, num, isKori = false) {
  let bd = Array(25).fill(0);
  for (let i = 0; i < num; i++) {
    bd[i] = amount / num * (isKori ? 181.44: 1);
  }
  return bd;
}

//----------------------------------------------------------------------
function formatDate(dt) {
  var y = dt.getFullYear();
  var m = ('00' + (dt.getMonth() + 1)).slice(-2);
  var d = ('00' + dt.getDate()).slice(-2);
  return (y + '-' + m + '-' + d);
}

function formattedDate(date) {
  return date.toLocaleDateString("ja-JP", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).split("/").join("-");
}

function getUnitString(unit) {
  if (unit == 'length') return 'm';
  else return 'kg';
}

//----------------------------------------------------------------------
function getAmountBdSimple(amountBd) {
  let ss = '';
  let count = 0;
  amountBd.forEach((v) => {
    if (v > 0) {
      ss += v.toFixed(2) + ', ';
      count++;
    }
  });
  if (ss.length > 0) ss = ss.slice(0, -2);
  return { count: count, amount: ss };
}

//----------------------------------------------------------------------
function getAmountBdSimpleKori(amountBd) {
  let ss = '';
  let count = 0;
  amountBd.forEach((v) => {
    if (v > 0) {
      ss += (v/181.44).toFixed(2) + ', ';
      count++;
    }
  });
  if (ss.length > 0) ss = ss.slice(0, -2);
  return { count: count, amount: ss };
}

//----------------------------------------------------------------------
function isNumeric(str) {
  return !isNaN(str) && !isNaN(parseFloat(str));
}