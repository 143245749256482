const locale = {
    "0": {
        "kr": "",
        "jp": "",
        "en": ""
    },
    "100": {
        "kr": "샘플 Vue 페이지",
        "jp": "サンプルVueページ",
        "en": "Sample Vue page"
    },
    "101": {
        "kr": "텍스트 입력",
        "jp": "テキストを入力",
        "en": "Input text"
    },
    "102": {
        "kr": "텍스트 출력",
        "jp": "テキストをセット",
        "en": "Set"
    },
    "103": {
        "kr": "텍스트 출력",
        "jp": "セットされたテキスト",
        "en": "The text"
    },
    "104": {
        "kr": "샘플 HTML 페이지",
        "jp": "サンプルHTMLページ",
        "en": "Sample HTML page"
    },
    "1000": {
        "kr": "생산 관리",
        "jp": "生産管理",
        "en": "Production Management"
    },
    "1101": {
        "kr": "본사",
        "jp": "本社",
        "en": "Head office"
    },
    "10001": {
        "kr": "메인",
        "jp": "メイン",
        "en": "Main"
    },
    "10002": {
        "kr": "원사 구매",
        "jp": "糸購入",
        "en": "Procure thread"
    },
    "10003": {
        "kr": "생지 구매",
        "jp": "生地購入",
        "en": "Procure cloth"
    },
    "10004": {
        "kr": "생지 생산",
        "jp": "生地生産",
        "en": "Process cloth"
    },
    "10005": {
        "kr": "제품 생산",
        "jp": "製品生産",
        "en": "Process product"
    },
    "10006": {
        "kr": "생지 생산 완료",
        "jp": "生地生産完了",
        "en": "Finish cloth"
    },
    "10007": {
        "kr": "제품 생산이 완료되었습니다",
        "jp": "製品生産完了",
        "en": "Finish product"
    },
    "10008": {
        "kr": "원사 이동",
        "jp": "糸移動",
        "en": "Move thread"
    },
    "10009": {
        "kr": "생지 이동",
        "jp": "生地移動",
        "en": "Move cloth"
    },
    "10010": {
        "kr": "제품 이동",
        "jp": "製品移動",
        "en": "Move product"
    },
    "10011": {
        "kr": "원사 지불",
        "jp": "糸支払い",
        "en": "Pay for thread"
    },
    "10012": {
        "kr": "생지 지불",
        "jp": "生地支払い",
        "en": "Pay for cloth"
    },
    "10013": {
        "kr": "제품 판매",
        "jp": "製品販売",
        "en": "Sell product"
    },
    "10014": {
        "kr": "수집 회수",
        "jp": "代金回収",
        "en": "Collect receivable"
    },
    "10015": {
        "kr": "염색 가공",
        "jp": "染め加工",
        "en": "Start dyeing a cloth"
    },
    "10016": {
        "kr": "염색 가공 완료",
        "jp": "染め加工完了",
        "en": "Finish dyeing a cloth"
    },
    "10017": {
        "kr": "등록",
        "jp": "登録",
        "en": "Registration"
    },
    "10018": {
        "kr": "원사 구입처 등록",
        "jp": "糸購入先登録",
        "en": "Thread supplier registration"
    },
    "10019": {
        "kr": "생지 구입처 등록",
        "jp": "生地購入先登録",
        "en": "Cloth supplier registration"
    },
    "10020": {
        "kr": "판매처 등록",
        "jp": "販売先登録",
        "en": "Customer registration"
    },
    "10021": {
        "kr": "공장 등록",
        "jp": "工場登録",
        "en": "Factory registration"
    },
    "10022": {
        "kr": "원사 등록",
        "jp": "糸登録",
        "en": "Thread registration"
    },
    "10023": {
        "kr": "원단 등록",
        "jp": "生地登録",
        "en": "Cloth registration"
    },
    "10024": {
        "kr": "컬러 등록",
        "jp": "色登録",
        "en": "Color registration"
    },
    "10025": {
        "kr": "제품 등록",
        "jp": "製品登録",
        "en": "Product registration"
    },
    "10026": {
        "kr": "구매",
        "jp": "購入",
        "en": "Procure"
    },
    "10027": {
        "kr": "생산",
        "jp": "生産",
        "en": "Production"
    },
    "10028": {
        "kr": "데이터 보기",
        "jp": "データビュー",
        "en": "Data view"
    },
    "10029": {
        "kr": "이동",
        "jp": "移動",
        "en": "Move"
    },
    "10030": {
        "kr": "판매",
        "jp": "販売",
        "en": "Sales"
    },
    "10031": {
        "kr": "지불·회수",
        "jp": "支払い・回収",
        "en": "Payment and collect"
    },
    "10032": {
        "kr": "기타",
        "jp": "その他",
        "en": "Etc"
    },
    "10033": {
        "kr": "추적",
        "jp": "追跡",
        "en": "Trace"
    },
    "10034": {
        "kr": "사용자 관리",
        "jp": "ユーザー管理",
        "en": "User management"
    },
    "10035": {
        "kr": "로그",
        "jp": "ログ",
        "en": "Log"
    },
    "10036": {
        "kr": "생지 생산 2",
        "jp": "生地生産２",
        "en": "Process cloth 2"
    },
    "10201": {
        "kr": "원사 선택",
        "jp": "糸の選択",
        "en": "Select a thread."
    },
    "10202": {
        "kr": "구매처 선택",
        "jp": "購入先の選択",
        "en": "Select a supplier."
    },
    "10203": {
        "kr": "단가 입력",
        "jp": "単価の入力",
        "en": "Fill in unit per price."
    },
    "10204": {
        "kr": "길이 또는 무게를 입력하십시오",
        "jp": "長さまたは重さを入力してください",
        "en": "Fill in length or weight."
    },
    "10205": {
        "kr": "길이",
        "jp": "長さ",
        "en": "Length"
    },
    "10206": {
        "kr": "무게",
        "jp": "重さ",
        "en": "Weight"
    },
    "10207": {
        "kr": "실행하다",
        "jp": "実行する",
        "en": "Execute"
    },
    "10208": {
        "kr": "원사 구매",
        "jp": "糸購入",
        "en": "Procure thread"
    },
    "10209": {
        "kr": "원사 구매 프로세스가 실행되었습니다",
        "jp": "糸購入処理を実行しました",
        "en": "The thread procure process completed.  "
    },
    "10210": {
        "kr": "처리 중",
        "jp": "処理中",
        "en": "Processing"
    },
    "10211": {
        "kr": "거래한 날짜를 입력하세요",
        "jp": "取引した日付を入力してください",
        "en": "Fill in the transaction date"
    },
    "10212": {
        "kr": "납품처 선택",
        "jp": "納品先の選択",
        "en": "Select a delivery destination."
    },
    "10213": {
        "kr": "입력 양식",
        "jp": "入力フォーム",
        "en": "Form"
    },
    "10214": {
        "kr": "상세내역",
        "jp": "内訳",
        "en": "Details"
    },
    "10215": {
        "kr": "입력 내용 표시",
        "jp": "入力内容表示",
        "en": "Inputed"
    },
    "10216": {
        "kr": "구매일",
        "jp": "購入日",
        "en": "Purchase date"
    },
    "10217": {
        "kr": "히스토리",
        "jp": "履歴",
        "en": "Record"
    },
    "10218": {
        "kr": "고리",
        "jp": "コリ",
        "en": "SNP",
    },
    "10219": {
        "kr": "구매할 물품이 추가되지 않았습니다.",
        "jp": "購入する物品が追加されていません",
        "en": "There is no item to purchase"
    },
    "10220": {
        "kr": "입력되지 않은 데이터가 있습니다.",
        "jp": "未入力のデータがあります",
        "en": "There is data not filled in"
    },
    "10301": {
        "kr": "원단 선택",
        "jp": "生地の選択",
        "en": "Select a cloth."
    },
    "10302": {
        "kr": "구매처 선택",
        "jp": "購入先の選択",
        "en": "Select a supplier."
    },
    "10303": {
        "kr": "단가 입력",
        "jp": "単価の入力",
        "en": "Fill in unit per price."
    },
    "10304": {
        "kr": "길이 또는 무게를 입력하십시오",
        "jp": "長さまたは重さを入力してください",
        "en": "Fill in length or weight."
    },
    "10305": {
        "kr": "길이",
        "jp": "長さ",
        "en": "Length"
    },
    "10306": {
        "kr": "무게",
        "jp": "重さ",
        "en": "Weight"
    },
    "10307": {
        "kr": "실행하다",
        "jp": "実行する",
        "en": "Execute"
    },
    "10308": {
        "kr": "원단 구매",
        "jp": "生地購入",
        "en": "Procure cloth"
    },
    "10309": {
        "kr": "원단 구매 완료했습니다",
        "jp": "生地購入処理を実行しました",
        "en": "The cloth procure process completed.  "
    },
    "10310": {
        "kr": "처리 중",
        "jp": "処理中",
        "en": "Processing"
    },
    "10311": {
        "kr": "거래 날짜를 입력해주세요",
        "jp": "取引した日付を入力してください",
        "en": "Fill in the transaction date"
    },
    "10312": {
        "kr": "납품처를 선택해 주세요",
        "jp": "納品先を選択してください",
        "en": "Select a delivery destination."
    },
    "10313": {
        "kr": "입력 양식",
        "jp": "入力フォーム",
        "en": "Form"
    },
    "10314": {
        "kr": "상세내역",
        "jp": "内訳",
        "en": "Details"
    },
    "10315": {
        "kr": "입력 내용 표시",
        "jp": "入力内容表示",
        "en": "Inputed"
    },
    "10316": {
        "kr": "구매일",
        "jp": "購入日",
        "en": "Purchase date"
    },
    "10317": {
        "kr": "이력",
        "jp": "履歴",
        "en": "Record"
    },
    "10318": {
        "kr": "처리 중...",
        "jp": "処理中...",
        "en": "Processing..."
    },
    "10401": {
        "kr": "생산 공장을 선택 해주세요.",
        "jp": "工場を選択してください。",
        "en": "Select a factory.  "
    },
    "10402": {
        "kr": "원사 목록에서 원사를 선택하고 비율 및 Loss 율을 입력한 다음 재료 리스트에 추가합니다.",
        "jp": "糸リストから糸を選択し、比率とロスを入力して材料リストに追加してください。",
        "en": "Select a thread from the thread list and add it to the material list with filling in ratio and loss."
    },
    "10403": {
        "kr": "원사 리스트",
        "jp": "糸リスト",
        "en": "Thread list"
    },
    "10404": {
        "kr": "비율(%)",
        "jp": "比率(%)",
        "en": "Ratio(%)"
    },
    "10405": {
        "kr": "로스(%)",
        "jp": "ロス(%)",
        "en": "Loss(%)"
    },
    "10406": {
        "kr": "추가",
        "jp": "追加する",
        "en": "Add"
    },
    "10407": {
        "kr": "원단 목록",
        "jp": "材料リスト",
        "en": "Material list"
    },
    "10408": {
        "kr": "생산할 원단을 선택하십시오.",
        "jp": "生産する生地を選択してください。",
        "en": "Select a cloth to be produced.  "
    },
    "10409": {
        "kr": "원사 투입량을 입력해 주세요.",
        "jp": "材料投入量を入力してください。",
        "en": "Fill in amount of material.  "
    },
    "10410": {
        "kr": "길이",
        "jp": "長さ",
        "en": "Length"
    },
    "10411": {
        "kr": "무게",
        "jp": "重さ",
        "en": "Weight"
    },
    "10412": {
        "kr": "가공 비용을 입력하십시오.",
        "jp": "加工費を入力してください。",
        "en": "Fill in process cost.  "
    },
    "10413": {
        "kr": "실행하다",
        "jp": "実行する",
        "en": "Execute"
    },
    "10414": {
        "kr": "생산 처리가 실행되었습니다.",
        "jp": "生産処理を実行しました。",
        "en": "The production process completed.  "
    },
    "10415": {
        "kr": "처리 중",
        "jp": "処理中",
        "en": "Processing"
    },
    "10416": {
        "kr": "삭제",
        "jp": "削除",
        "en": "Remove"
    },
    "10417": {
        "kr": "변경",
        "jp": "変更",
        "en": "Replace"
    },
    "10418": {
        "kr": "소재",
        "jp": "材料",
        "en": "Material"
    },
    "10419": {
        "kr": "입력 양식",
        "jp": "入力フォーム",
        "en": "Form"
    },
    "10420": {
        "kr": "이력",
        "jp": "履歴",
        "en": "Record"
    },
    "10421": {
        "kr": "생산량을 입력하세요",
        "jp": "生産量を入力してください",
        "en": "Fill in the production amount"
    },
    "10422": {
        "kr": "절수를 입력",
        "jp": "個数を入力してください",
        "en": "Fill in the number of products"
    },
    "10423": {
        "kr": "절",
        "jp": "個",
        "en": "pieces"
    },
    "10424": {
        "kr": "소재가 지정되지 않음",
        "jp": "材料が指定されていません",
        "en": "Material is not specified"
    },
    "10425": {
        "kr": "가공비가 입력되지 않았습니다.",
        "jp": "加工費が入力されていません",
        "en": "Process cost is not filled in"
    },
    "10426": {
        "kr": "재료의 합계가 100%가 아닙니다.",
        "jp": "材料の合計が100%になっていません",
        "en": "The total of material is not 100%"
    },
    "10501": {
        "kr": "공장을 선택해 주세요.",
        "jp": "工場を選択してください。",
        "en": "Select a factory.  "
    },
    "10502": {
        "kr": "재고토리 리스트에서 재료를 선택하고 비율 및 로스를 입력한 다음 재료 목록에 추가해 주세요",
        "jp": "在庫リストから材料を選択し、比率とロスを入力して材料リストに追加してください。",
        "en": "Select a material from the inventory list and add it to the material list with filling in ratio and loss."
    },
    "10503": {
        "kr": "재고 목록",
        "jp": "在庫リスト",
        "en": "Inventory list"
    },
    "10504": {
        "kr": "비율",
        "jp": "比率",
        "en": "Ratio"
    },
    "10505": {
        "kr": "로스",
        "jp": "ロス",
        "en": "Loss"
    },
    "10506": {
        "kr": "추가",
        "jp": "追加する",
        "en": "Add"
    },
    "10507": {
        "kr": "재료 목록",
        "jp": "材料リスト",
        "en": "Material list"
    },
    "10508": {
        "kr": "제품을 선택해 주세요.",
        "jp": "生産品を選択してください。",
        "en": "Select a product.  "
    },
    "10509": {
        "kr": "재료 투입량을 입력해 주세요.",
        "jp": "材料投入量を入力してください。",
        "en": "Fill in amount of material.  "
    },
    "10510": {
        "kr": "길이",
        "jp": "長さ",
        "en": "Length"
    },
    "10511": {
        "kr": "무게",
        "jp": "重さ",
        "en": "Weight"
    },
    "10512": {
        "kr": "가공 비용을 입력해 주세요.",
        "jp": "加工費を入力してください。",
        "en": "Fill in process cost.  "
    },
    "10513": {
        "kr": "실행하다",
        "jp": "実行する",
        "en": "Execute"
    },
    "10514": {
        "kr": "생산 처리가 실행되었습니다.",
        "jp": "生産処理を実行しました。",
        "en": "The production process completed.  "
    },
    "10515": {
        "kr": "처리 중",
        "jp": "処理中",
        "en": "Processing"
    },
    "10516": {
        "kr": "삭제",
        "jp": "削除",
        "en": "Remove"
    },
    "10517": {
        "kr": "변경",
        "jp": "変更",
        "en": "Replace"
    },
    "10518": {
        "kr": "소재",
        "jp": "材料",
        "en": "Material"
    },
    "10519": {
        "kr": "입력 양식",
        "jp": "入力フォーム",
        "en": "Form"
    },
    "10520": {
        "kr": "이력",
        "jp": "履歴",
        "en": "Record"
    },
    "10601": {
        "kr": "원단 공장을 선택해 주세요.",
        "jp": "生地工場を選択してください。",
        "en": "Select a cloth factory."
    },
    "10602": {
        "kr": "생산 완료가된 원단을 선택해 주세요.",
        "jp": "生産が完了した生地を選択してください。",
        "en": "Select a cloth finished."
    },
    "10603": {
        "kr": "결과를 입력해 주세요.",
        "jp": "結果を入力してください。",
        "en": "Fill in the result."
    },
    "10604": {
        "kr": "길이",
        "jp": "長さ",
        "en": "Length"
    },
    "10605": {
        "kr": "무게",
        "jp": "重さ",
        "en": "Weight"
    },
    "10606": {
        "kr": "실행",
        "jp": "実行",
        "en": "Execute"
    },
    "10607": {
        "kr": "생산 완료 조리를  실행했습니다.",
        "jp": "生産完了処理を実行しました。",
        "en": "The production finish process completed.  "
    },
    "10608": {
        "kr": "처리 중",
        "jp": "処理中",
        "en": "Processing"
    },
    "10701": {
        "kr": "가공 공장을 선택해 주세요.",
        "jp": "加工工場を選択してください。",
        "en": "Select a process factory."
    },
    "10702": {
        "kr": "생산이 완료된 제품을 선택해 주세요.",
        "jp": "生産が完了した製品を選択してください。",
        "en": "Select the product finished."
    },
    "10703": {
        "kr": "결과를 입력해 주세요.",
        "jp": "結果を入力してください。",
        "en": "Fill in the result."
    },
    "10704": {
        "kr": "길이",
        "jp": "長さ",
        "en": "Length"
    },
    "10705": {
        "kr": "무게",
        "jp": "重さ",
        "en": "Weight"
    },
    "10706": {
        "kr": "실행",
        "jp": "実行",
        "en": "Execute"
    },
    "10707": {
        "kr": "생산 완료 조리를 실행했습니다.",
        "jp": "生産完了処理を実行しました。",
        "en": "The production finish process completed.  "
    },
    "10708": {
        "kr": "처리 중",
        "jp": "処理中",
        "en": "Processing"
    },
    "10801": {
        "kr": "출발지를 선택해 주세요",
        "jp": "移動元を選択してください。",
        "en": "Select an origin."
    },
    "10802": {
        "kr": "도착지를 선택해 주세요",
        "jp": "移動先を選択してください。",
        "en": "Select a destination."
    },
    "10803": {
        "kr": "이동할 원사를 선택해 주세요.",
        "jp": "移動する糸を選択してください。",
        "en": "Select the thread to move."
    },
    "10804": {
        "kr": "원사 양을 입력해 주세요.",
        "jp": "移動量を入力してください。",
        "en": "Fill in the amount of move."
    },
    "10805": {
        "kr": "길이",
        "jp": "長さ",
        "en": "Length"
    },
    "10806": {
        "kr": "무게",
        "jp": "重さ",
        "en": "Weight"
    },
    "10807": {
        "kr": "실행",
        "jp": "実行",
        "en": "Execute"
    },
    "10808": {
        "kr": "이동 조리가 완료되었습니다.",
        "jp": "移動処理を実行しました。",
        "en": "The move process completed.  "
    },
    "10901": {
        "kr": "출발지를 선택해 주세요.",
        "jp": "移動元を選択してください。",
        "en": "Select an origin."
    },
    "10902": {
        "kr": "목적지를 선택해 주세요.",
        "jp": "移動先を選択してください。",
        "en": "Select a destination."
    },
    "10903": {
        "kr": "이동할 원단을 선택해 주세요.",
        "jp": "移動する生地を選択してください。",
        "en": "Select the cloth to move."
    },
    "10904": {
        "kr": "이동량을 선택해 주세요.",
        "jp": "移動量を入力してください。",
        "en": "Fill in the amount of move."
    },
    "10905": {
        "kr": "길이",
        "jp": "長さ",
        "en": "Length"
    },
    "10906": {
        "kr": "무게",
        "jp": "重さ",
        "en": "Weight"
    },
    "10907": {
        "kr": "실행",
        "jp": "実行",
        "en": "Execute"
    },
    "10908": {
        "kr": "이동 조리를 실행 했습니다.",
        "jp": "移動処理を実行しました。",
        "en": "The move process completed.  "
    },
    "11001": {
        "kr": "출발지를 선택해 주세요.",
        "jp": "移動元を選択してください。",
        "en": "Select an origin."
    },
    "11002": {
        "kr": "목적지를 선택해 주세요.",
        "jp": "移動先を選択してください。",
        "en": "Select a destination."
    },
    "11003": {
        "kr": "이동할 원단을 선택해 주세요.",
        "jp": "移動する生地を選択してください。",
        "en": "Select the cloth to move."
    },
    "11004": {
        "kr": "이동량을 선택해 주세요.",
        "jp": "移動量を入力してください。",
        "en": "Fill in the amount of move."
    },
    "11005": {
        "kr": "길이",
        "jp": "長さ",
        "en": "Length"
    },
    "11006": {
        "kr": "무게",
        "jp": "重さ",
        "en": "Weight"
    },
    "11007": {
        "kr": "실행",
        "jp": "実行",
        "en": "Execute"
    },
    "11008": {
        "kr": "이동 조리를  실행 했습니다.",
        "jp": "移動処理を実行しました。",
        "en": "The move process completed.  "
    },
    "11101": {
        "kr": "지불 대상을 선택해 주세요.",
        "jp": "支払い先を選択してください。",
        "en": "Select a payment destination."
    },
    "11102": {
        "kr": "금액을 입력해 주세요.",
        "jp": "金額を入力してください。",
        "en": "Fill in the amount."
    },
    "11103": {
        "kr": "실행",
        "jp": "実行",
        "en": "Execute"
    },
    "11104": {
        "kr": "결제가 완료되었습니다.",
        "jp": "支払い処理を実行しました。",
        "en": "The payment process completed.  "
    },
    "11201": {
        "kr": "결제 대상을 선택해 주세요.",
        "jp": "支払い先を選択してください。",
        "en": "Select a payment destination."
    },
    "11202": {
        "kr": "금액을 입력해 주세요.",
        "jp": "金額を入力してください。",
        "en": "Fill in the amount."
    },
    "11203": {
        "kr": "실행",
        "jp": "実行",
        "en": "Execute"
    },
    "11204": {
        "kr": "결제가 완료되었습니다.",
        "jp": "支払い処理を実行しました。",
        "en": "The payment process completed.  "
    },
    "11301": {
        "kr": "제품을 선택해 주세요.",
        "jp": "製品を選択してください。",
        "en": "Select a product."
    },
    "11302": {
        "kr": "판매처를 선택해 주세요.",
        "jp": "販売先を選択してください",
        "en": "Select a customer."
    },
    "11303": {
        "kr": "단가를 입력해 주세요.",
        "jp": "単価を入力してください",
        "en": "Fill in unit per price."
    },
    "11304": {
        "kr": "길이 또는 무게를 입력해 주세요.",
        "jp": "長さまたは重さを入力してください",
        "en": "Fill in length or weight."
    },
    "11305": {
        "kr": "길이",
        "jp": "長さ",
        "en": "Length"
    },
    "11306": {
        "kr": "무게",
        "jp": "重さ",
        "en": "Weight"
    },
    "11307": {
        "kr": "실행",
        "jp": "実行",
        "en": "Execute"
    },
    "11308": {
        "kr": "매출처",
        "jp": "販売先",
        "en": "Customer"
    },
    "11309": {
        "kr": "판매가 완료되었습니다.",
        "jp": "販売処理を実行しました",
        "en": "The sales process completed.  "
    },
    "11401": {
        "kr": "회수 대상을 선택해 주세요.",
        "jp": "回収先を選択してください。",
        "en": "Select a customer."
    },
    "11402": {
        "kr": "금액을 입력해 주세요.",
        "jp": "金額を入力してください。",
        "en": "Fill in the amount."
    },
    "11403": {
        "kr": "실행",
        "jp": "実行",
        "en": "Execute"
    },
    "11404": {
        "kr": "회수가 완료되었습니다.",
        "jp": "回収処理を実行しました。",
        "en": "The collection process completed.  "
    },
    "11501": {
        "kr": "비용 추적",
        "jp": "費用追跡",
        "en": "Cost trace"
    },
    "11502": {
        "kr": "물품을 선택해 주세요.",
        "jp": "物品を選択してください",
        "en": "Select an item to be traced.  "
    },
    "11503": {
        "kr": "원단비",
        "jp": "材料費",
        "en": "Material cost"
    },
    "11504": {
        "kr": "가공비",
        "jp": "加工費",
        "en": "Process cost"
    },
    "11505": {
        "kr": "추적 데이터",
        "jp": "追跡データ",
        "en": "Trace data"
    },
    "11506": {
        "kr": "상품명",
        "jp": "商品名",
        "en": "Product name"
    },
    "11507": {
        "kr": "최종 재고",
        "jp": "最終在庫",
        "en": "Last stock"
    },
    "11508": {
        "kr": "상태",
        "jp": "状態",
        "en": "Status"
    },
    "11509": {
        "kr": "구매품",
        "jp": "購入品",
        "en": "Procured"
    },
    "11510": {
        "kr": "생산 중",
        "jp": "生産中",
        "en": "Processing"
    },
    "11511": {
        "kr": "생산 완료",
        "jp": "生産完了",
        "en": "Finished"
    },
    "11512": {
        "kr": "날짜",
        "jp": "日付",
        "en": "Date"
    },
    "11513": {
        "kr": "내용",
        "jp": "内容",
        "en": "Items"
    },
    "11514": {
        "kr": "단가",
        "jp": "単価",
        "en": "Price per unit"
    },
    "11515": {
        "kr": "투입 수량",
        "jp": "投入数量",
        "en": "Input amount"
    },
    "11516": {
        "kr": "상세내역",
        "jp": "内訳",
        "en": "Breakdown"
    },
    "11517": {
        "kr": "원단비",
        "jp": "材料費",
        "en": "Material cost"
    },
    "11518": {
        "kr": "가공비",
        "jp": "加工費",
        "en": "Process cost"
    },
    "11519": {
        "kr": "결과 수량",
        "jp": "結果数量",
        "en": "Output amount"
    },
    "11520": {
        "kr": "상세내역",
        "jp": "内訳",
        "en": "Breakdown"
    },
    "11521": {
        "kr": "검색 조건",
        "jp": "検索条件",
        "en": "Search"
    },
    "11522": {
        "kr": "시작",
        "jp": "開始",
        "en": "Begin"
    },
    "11523": {
        "kr": "종료",
        "jp": "終了",
        "en": "End"
    },
    "11524": {
        "kr": "로스률",
        "jp": "ロス率",
        "en": "Loss ratio"
    },
    "11525": {
        "kr": "비율",
        "jp": "原価",
        "en": "Cost price"
    },
    "11601": {
        "kr": "공장을 선택해 주세요.",
        "jp": "工場を選択してください。",
        "en": "Select a factory.  "
    },
    "11602": {
        "kr": "생지을 선택해 주세요.",
        "jp": "生地を選択してください。",
        "en": "Select a cloth."
    },
    "11603": {
        "kr": "컬러를 선택해 주세요.",
        "jp": "色を選択してください。",
        "en": "Select a color."
    },
    "11604": {
        "kr": "원단 투입량을 입력해 주세요.",
        "jp": "材料投入量を入力してください。",
        "en": "Fill in amount of material.  "
    },
    "11605": {
        "kr": "길이",
        "jp": "長さ",
        "en": "Length"
    },
    "11606": {
        "kr": "무게",
        "jp": "重さ",
        "en": "Weight"
    },
    "11607": {
        "kr": "가공비를 입력해 주세요.",
        "jp": "加工費を入力してください。",
        "en": "Fill in process cost.  "
    },
    "11608": {
        "kr": "실행",
        "jp": "実行",
        "en": "Execute"
    },
    "11609": {
        "kr": "염색 가공을 실행했습니다.",
        "jp": "染め加工を実行しました。",
        "en": "Dyeing process completed."
    },
    "11610": {
        "kr": "처리 중",
        "jp": "処理中",
        "en": "Processing"
    },
    "11611": {
        "kr": "염색",
        "jp": "染色",
        "en": "Dyeing"
    },
    "11701": {
        "kr": "염색 공장을 선택해 주세요.",
        "jp": "染め工場を選択してください。",
        "en": "Select a dyeing factory."
    },
    "11702": {
        "kr": "염색 가공 완료된 제품을 선택합니다.",
        "jp": "染め加工が完了した製品を選択してください。",
        "en": "Select the product finished."
    },
    "11703": {
        "kr": "결과를 입력해 주세요.",
        "jp": "結果を入力してください。",
        "en": "Fill in the result."
    },
    "11704": {
        "kr": "길이",
        "jp": "長さ",
        "en": "Length"
    },
    "11705": {
        "kr": "무게",
        "jp": "重さ",
        "en": "Weight"
    },
    "11706": {
        "kr": "실행",
        "jp": "実行",
        "en": "Execute"
    },
    "11707": {
        "kr": "염색 가공 완료 처리를 실행했습니다.",
        "jp": "染め加工完了処理を実行しました。",
        "en": "The dyeing finish process completed.  "
    },
    "11708": {
        "kr": "처리 중",
        "jp": "処理中",
        "en": "Processing"
    },
    "20001": {
        "kr": "구매 기록",
        "jp": "購入記録",
        "en": "Procure record"
    },
    "20002": {
        "kr": "ID",
        "jp": "ID",
        "en": "ID"
    },
    "20003": {
        "kr": "거래처 ID",
        "jp": "取引先ID",
        "en": "Supplier ID"
    },
    "20004": {
        "kr": "구매처 명칭",
        "jp": "購入先名称",
        "en": "Supplier name"
    },
    "20005": {
        "kr": "형태",
        "jp": "形態",
        "en": "Type"
    },
    "20006": {
        "kr": "상품",
        "jp": "商品",
        "en": "Merchandise"
    },
    "20007": {
        "kr": "상품명",
        "jp": "商品名",
        "en": "Merchandise name"
    },
    "20008": {
        "kr": "단가",
        "jp": "単価",
        "en": "Unit price"
    },
    "20009": {
        "kr": "길이",
        "jp": "長さ",
        "en": "Length"
    },
    "20010": {
        "kr": "무게",
        "jp": "重さ",
        "en": "Weight"
    },
    "20011": {
        "kr": "가격",
        "jp": "価格",
        "en": "Price"
    },
    "20012": {
        "kr": "메모",
        "jp": "メモ",
        "en": "Memo"
    },
    "20013": {
        "kr": "날짜",
        "jp": "日付",
        "en": "Date"
    },
    "20014": {
        "kr": "상세내역",
        "jp": "内訳",
        "en": "Breakdown"
    },
    "20015": {
        "kr": "납품처 ID",
        "jp": "納品先ID",
        "en": "Delivery ID"
    },
    "20016": {
        "kr": "납품처 명칭",
        "jp": "納品先名称",
        "en": "Delivery name"
    },
    "30001": {
        "kr": "가공 기록",
        "jp": "加工記録",
        "en": "Process record"
    },
    "30002": {
        "kr": "ID",
        "jp": "ID",
        "en": "ID"
    },
    "30003": {
        "kr": "공장 번호",
        "jp": "工場番号",
        "en": "Factory number"
    },
    "30004": {
        "kr": "공장명",
        "jp": "工場名称",
        "en": "Factory name"
    },
    "30005": {
        "kr": "생산품 종류",
        "jp": "生産品種類",
        "en": "Product type"
    },
    "30006": {
        "kr": "생산품 번호",
        "jp": "生産品番号",
        "en": "Product number"
    },
    "30007": {
        "kr": "생산품명",
        "jp": "生産品名称",
        "en": "Product name"
    },
    "30008": {
        "kr": "원단 투입량(m)",
        "jp": "材料投入量(m)",
        "en": "Material amount (m)"
    },
    "30009": {
        "kr": "원단 투입량(kg)",
        "jp": "材料投入量(kg)",
        "en": "Material amount (kg)"
    },
    "30010": {
        "kr": "생산 결과(m)",
        "jp": "実際生産量(m)",
        "en": "Actual production amount (m)"
    },
    "30011": {
        "kr": "생산 결과(kg)",
        "jp": "実際生産量(kg)",
        "en": "Actual production amount (kg)"
    },
    "30012": {
        "kr": "가공비",
        "jp": "加工費",
        "en": "Processing cost"
    },
    "30013": {
        "kr": "날짜",
        "jp": "日付",
        "en": "Date"
    },
    "30014": {
        "kr": "상세내역",
        "jp": "内訳",
        "en": "Breakdown"
    },
    "30015": {
        "kr": "칼라",
        "jp": "色",
        "en": "Color"
    },
    "30016": {
        "kr": "내역(가공 전)",
        "jp": "内訳（加工前）",
        "en": "Breakdown (pre)"
    },
    "30017": {
        "kr": "내역(가공 후)",
        "jp": "内訳（加工後）",
        "en": "Breakdown (post)"
    },
    "30018": {
        "kr": "원단 투입량",
        "jp": "材料投入量",
        "en": "Material amount"
    },
    "30019": {
        "kr": "생산량 결과",
        "jp": "実際生産量",
        "en": "Actual production amount"
    },
    "40001": {
        "kr": "결제 및 수집 기록",
        "jp": "支払い回収記録",
        "en": "Payment & collect record"
    },
    "40002": {
        "kr": "ID",
        "jp": "ID",
        "en": "ID"
    },
    "40003": {
        "kr": "거래 종류",
        "jp": "取引種類",
        "en": "Transaction type"
    },
    "40004": {
        "kr": "비즈니스 파트너 ID",
        "jp": "取引先ID",
        "en": "Supplier ID"
    },
    "40005": {
        "kr": "비즈니스 파트너",
        "jp": "取引先",
        "en": "Suppliers"
    },
    "40006": {
        "kr": "거래 금액",
        "jp": "取引金額",
        "en": "Transaction amount"
    },
    "40007": {
        "kr": "날짜",
        "jp": "日付",
        "en": "Date"
    },
    "100001": {
        "kr": "이름",
        "jp": "名称",
        "en": "Name"
    },
    "100002": {
        "kr": "추가/업데이트",
        "jp": "追加/更新",
        "en": "Add/Update"
    },
    "100003": {
        "kr": "체크 항목 삭제",
        "jp": "チェック項目を削除",
        "en": "Delete checked items"
    },
    "100004": {
        "kr": "데이터베이스 업데이트",
        "jp": "データベースを更新",
        "en": "Update database"
    },
    "100005": {
        "kr": "CSV 파일 불러오기",
        "jp": "CSVファイルを読み込む",
        "en": "Load csv file"
    },
    "100006": {
        "kr": "ID",
        "jp": "ID",
        "en": "ID"
    },
    "100007": {
        "kr": "이미 등록되었습니다",
        "jp": "すでに登録されています",
        "en": "This item is already registered.  "
    },
    "100008": {
        "kr": "데이터 추가에 실패했습니다.",
        "jp": "データ追加に失敗しました",
        "en": "Adding data was in failure.  "
    },
    "100009": {
        "kr": "입력 양식",
        "jp": "入力フォーム",
        "en": "Form"
    },
    "100010": {
        "kr": "등록 목록",
        "jp": "登録リスト",
        "en": "List of registered items"
    },
    "100011": {
        "kr": "입력되지 않았거나 잘못 입력된 항목이 있습니다.",
        "jp": "未入力または間違った入力の項目があります。",
        "en": "There are items that are not entered or incorrectly entered."
    },
    "100012": {
        "kr": "등록했습니다.",
        "jp": "登録しました。",
        "en": "The item has been registered."
    },
    "100013": {
        "kr": "삭제했습니다.",
        "jp": "削除しました。",
        "en": "The item has been deleted."
    },
    "100014": {
        "kr": "(입력은 선택 사항입니다)",
        "jp": "（入力は任意です）",
        "en": "(optional)"
    },
    "100015": {
        "jp": "入力されたIDは使用されています。データを上書きしますか？",
        "kr": "입력한 ID가 사용 중입니다. 데이터를 덮어쓰시겠습니까?",
        "en": "The ID is already registered. Do you want to overwrite it?"
    },
    "120001": {
        "kr": "원사매입처",
        "jp": "糸購入先",
        "en": "Thread supplier"
    },
    "120002": {
        "kr": "ID",
        "jp": "ID",
        "en": "ID"
    },
    "120003": {
        "kr": "회 사 명",
        "jp": "社名",
        "en": "Company name"
    },
    "120004": {
        "kr": "구매금",
        "jp": "買掛金",
        "en": "Accounts payable"
    },
    "130001": {
        "kr": "생지 매입처",
        "jp": "生地購入先",
        "en": "Cloth supplier"
    },
    "130002": {
        "kr": "ID",
        "jp": "ID",
        "en": "ID"
    },
    "130003": {
        "kr": "회사명",
        "jp": "社名",
        "en": "Company name"
    },
    "130004": {
        "kr": "구매 금액",
        "jp": "買掛金",
        "en": "Accounts payable"
    },
    "160001": {
        "kr": "실",
        "jp": "糸",
        "en": "Thread"
    },
    "160002": {
        "kr": "원사 번호",
        "jp": "糸番号",
        "en": "Thread number"
    },
    "160003": {
        "kr": "원사 명칭",
        "jp": "糸名称",
        "en": "Thread name"
    },
    "170001": {
        "kr": "생지",
        "jp": "生地",
        "en": "Cloth"
    },
    "170002": {
        "kr": "생지 번호",
        "jp": "生地番号",
        "en": "Cloth number"
    },
    "170003": {
        "kr": "생지 이름",
        "jp": "生地名称",
        "en": "Cloth name"
    },
    "170004": {
        "kr": "칼라",
        "jp": "色",
        "en": "Color"
    },
    "180001": {
        "kr": "제품",
        "jp": "製品",
        "en": "Product"
    },
    "180002": {
        "kr": "제품 번호",
        "jp": "製品番号",
        "en": "Product number"
    },
    "180003": {
        "kr": "제품명",
        "jp": "製品名称",
        "en": "Product name"
    },
    "180004": {
        "kr": "칼라",
        "jp": "色",
        "en": "Color"
    },
    "190001": {
        "kr": "로그",
        "jp": "ログ",
        "en": "Log"
    },
    "200001": {
        "kr": "가공 명세",
        "jp": "加工明細",
        "en": "Details record"
    },
    "200002": {
        "kr": "ID",
        "jp": "ID",
        "en": "ID"
    },
    "200003": {
        "kr": "가공 기록 ID",
        "jp": "加工記録ID",
        "en": "Processing record ID"
    },
    "200004": {
        "kr": "원단 종류",
        "jp": "材料種類",
        "en": "Material type"
    },
    "200005": {
        "kr": "원단 번호",
        "jp": "材料番号",
        "en": "Material number"
    },
    "200006": {
        "kr": "원단명",
        "jp": "材料名称",
        "en": "Material name"
    },
    "200007": {
        "kr": "원단량(m)",
        "jp": "材料量(m)",
        "en": "Material amount (m)"
    },
    "200008": {
        "kr": "원단량(kg)",
        "jp": "材料量(kg)",
        "en": "Material amount (kg)"
    },
    "200009": {
        "kr": "원단 로스",
        "jp": "材料ロス",
        "en": "Material loss"
    },
    "200010": {
        "kr": "공장 재고 ID",
        "jp": "工場在庫ID",
        "en": "Factory Inventory ID"
    },
    "200011": {
        "kr": "날짜",
        "jp": "日付",
        "en": "Date"
    },
    "200012": {
        "kr": "원단량",
        "jp": "材料量",
        "en": "Material amount"
    },
    "210001": {
        "kr": "이동 기록",
        "jp": "移動記録",
        "en": "Move record"
    },
    "210002": {
        "kr": "ID",
        "jp": "ID",
        "en": "ID"
    },
    "210003": {
        "kr": "수출 소스 ID",
        "jp": "移動元ID",
        "en": "Origin ID"
    },
    "210004": {
        "kr": "수출처 명칭",
        "jp": "移動元名称",
        "en": "Origin name"
    },
    "210005": {
        "kr": "제품 ID",
        "jp": "移動先ID",
        "en": "Destination ID"
    },
    "210006": {
        "kr": "수출처 명칭",
        "jp": "移動先名称",
        "en": "Destination name"
    },
    "210007": {
        "kr": "수출 항목 유형",
        "jp": "移動品種類",
        "en": "Moved item type"
    },
    "210008": {
        "kr": "제품 ID",
        "jp": "移動品ID",
        "en": "Moved item ID"
    },
    "210009": {
        "kr": "제품명 명칭",
        "jp": "移動品名称",
        "en": "Moved item name"
    },
    "210010": {
        "kr": "제품 수량(m)",
        "jp": "移動品数量(m)",
        "en": "Moved item amount (m)"
    },
    "210011": {
        "kr": "제품 수량(kg)",
        "jp": "移動品数量(kg)",
        "en": "Moved item amount (kg)"
    },
    "210012": {
        "kr": "날짜",
        "jp": "日付",
        "en": "Date"
    },
    "210013": {
        "kr": "상세내역",
        "jp": "内訳",
        "en": "Breakdown"
    },
    "210014": {
        "kr": "매출품 수량",
        "jp": "移動品数量",
        "en": "Moved item amount"
    },
    "210015": {
        "kr": "매출처 종류",
        "jp": "移動元種類",
        "en": "Origin type"
    },
    "210016": {
        "kr": "메출품 종류",
        "jp": "移動先種類",
        "en": "Destination type"
    },
    "220001": {
        "kr": "공장 재고",
        "jp": "工場在庫",
        "en": "Factory Inventory"
    },
    "220002": {
        "kr": "ID",
        "jp": "ID",
        "en": "ID"
    },
    "220003": {
        "kr": "공장 번호",
        "jp": "工場番号",
        "en": "Factory number"
    },
    "220004": {
        "kr": "공장명칭",
        "jp": "工場名称",
        "en": "Factory name"
    },
    "220005": {
        "kr": "물품 종류",
        "jp": "物品種類",
        "en": "Item type"
    },
    "220006": {
        "kr": "물품번호",
        "jp": "物品番号",
        "en": "Item number"
    },
    "220007": {
        "kr": "물품명칭",
        "jp": "物品名称",
        "en": "Item name"
    },
    "220008": {
        "kr": "재고(m)",
        "jp": "在庫(m)",
        "en": "Inventory (m)"
    },
    "220009": {
        "kr": "재고(kg)",
        "jp": "在庫(kg)",
        "en": "Inventory (kg)"
    },
    "220010": {
        "kr": "상태",
        "jp": "状態",
        "en": "Status"
    },
    "220011": {
        "kr": "기록 ID",
        "jp": "記録ID",
        "en": "Record ID"
    },
    "220012": {
        "kr": "날짜",
        "jp": "日付",
        "en": "Date"
    },
    "220013": {
        "kr": "칼라",
        "jp": "色",
        "en": "Color"
    },
    "220101": {
        "kr": "물품",
        "jp": "物品",
        "en": "Item"
    },
    "220102": {
        "kr": "원단비",
        "jp": "材料費",
        "en": "Material cost"
    },
    "220103": {
        "kr": "가공비",
        "jp": "加工費",
        "en": "Process cost"
    },
    "220104": {
        "kr": "단가",
        "jp": "単価",
        "en": "Price per unit"
    },
    "220105": {
        "kr": "수량",
        "jp": "数量",
        "en": "Amount"
    },
    "220106": {
        "kr": "재고",
        "jp": "在庫",
        "en": "Inventory"
    },
    "220107": {
        "kr": "단위",
        "jp": "単位",
        "en": "Unit"
    },
    "230001": {
        "kr": "공장",
        "jp": "工場",
        "en": "Factory"
    },
    "230002": {
        "kr": "공장 번호",
        "jp": "工場番号",
        "en": "Factory number"
    },
    "230003": {
        "kr": "공장 종류",
        "jp": "工場種類",
        "en": "Factory type"
    },
    "230004": {
        "kr": "공장명",
        "jp": "工場名",
        "en": "Factory name"
    },
    "240001": {
        "kr": "판매처",
        "jp": "販売先",
        "en": "Customer"
    },
    "240002": {
        "kr": "ID",
        "jp": "ID",
        "en": "ID"
    },
    "240003": {
        "kr": "회사명",
        "jp": "社名",
        "en": "Company name"
    },
    "240004": {
        "kr": "매출금액",
        "jp": "売掛金",
        "en": "Accounts receivable"
    },
    "250001": {
        "kr": "판매 기록",
        "jp": "販売記録",
        "en": "Sales record"
    },
    "250002": {
        "kr": "ID",
        "jp": "ID",
        "en": "ID"
    },
    "250003": {
        "kr": "판매처 ID",
        "jp": "販売先ID",
        "en": "Customer ID"
    },
    "250004": {
        "kr": "판매처",
        "jp": "販売先",
        "en": "Customer name"
    },
    "250005": {
        "kr": "제품 번호",
        "jp": "製品番号",
        "en": "Product No"
    },
    "250006": {
        "kr": "제품명",
        "jp": "製品名称",
        "en": "Product name"
    },
    "250007": {
        "kr": "단가",
        "jp": "単価",
        "en": "Unit price"
    },
    "250008": {
        "kr": "제품 수량(m)",
        "jp": "製品数量(m)",
        "en": "Product amount (m)"
    },
    "250009": {
        "kr": "제품 수량(kg)",
        "jp": "製品数量(kg)",
        "en": "Product amount (kg)"
    },
    "250010": {
        "kr": "판매 금액",
        "jp": "売上金額",
        "en": "Sales amount"
    },
    "250011": {
        "kr": "메모",
        "jp": "メモ",
        "en": "Memo"
    },
    "250012": {
        "kr": "날짜",
        "jp": "日付",
        "en": "Date"
    },
    "250013": {
        "kr": "제품 수량",
        "jp": "製品数量",
        "en": "Product amount"
    },
    "260001": {
        "kr": "칼라",
        "jp": "色",
        "en": "Color"
    },
    "260002": {
        "kr": "ID",
        "jp": "ID",
        "en": "ID"
    },
    "260003": {
        "kr": "칼라",
        "jp": "色",
        "en": "Color"
    },
    "260004": {
        "kr": "번호",
        "jp": "番号",
        "en": "Number"
    },
    "260005": {
        "kr": "이름",
        "jp": "名前",
        "en": "Name"
    },
    "260006": {
        "kr": "BT 번호",
        "jp": "BT番号",
        "en": "BT number"
    },
    "260007": {
        "kr": "정보 1",
        "jp": "情報１",
        "en": "Information 1"
    },
    "260008": {
        "kr": "정보 2",
        "jp": "情報２",
        "en": "Information 2"
    },
    "260009": {
        "kr": "정보 3",
        "jp": "情報３",
        "en": "Information 3"
    },
    "270001": {
        "kr": "UID",
        "jp": "UID",
        "en": "UID"
    },
    "270002": {
        "kr": "이메일 주소",
        "jp": "メールアドレス",
        "en": "email address"
    },
    "270003": {
        "kr": "비밀번호",
        "jp": "パスワード",
        "en": "password"
    },
    "270004": {
        "kr": "비밀번호 확인",
        "jp": "パスワード確認",
        "en": "password confirmation"
    },
    "270005": {
        "kr": "데이터베이스",
        "jp": "データベース",
        "en": "database"
    },
    "270006": {
        "kr": "권한",
        "jp": "権限",
        "en": "authority"
    },
    "270007": {
        "kr": "만료일",
        "jp": "有効期限",
        "en": "expiration"
    },
    "270008": {
        "kr": "추가",
        "jp": "追加",
        "en": "Add"
    },
    "270009": {
        "kr": "비밀번호가 일치하지 않음",
        "jp": "パスワードが一致していません",
        "en": "Passwords do not match."
    },
    "270010": {
        "kr": "확인된 사용자 삭제",
        "jp": "チェックされたユーザーを削除",
        "en": "Delete checked users"
    },
    "270011": {
        "kr": "사용자를 추가했습니다. 새 사용자로 로그인",
        "jp": "ユーザーを追加しました。新しいユーザーでログインします",
        "en": "The user is added.  Now the new user is logged in."
    },
    "270012": {
        "kr": "확인",
        "jp": "確認",
        "en": "Confirmation"
    },
    "270013": {
        "kr": "사용자를 삭제할 수 있습니까?",
        "jp": "ユーザーを削除してよいですか？",
        "en": "Do you really want to delete the users?"
    },
    "270014": {
        "kr": "이메일 주소가 올바르지 않습니다.",
        "jp": "メールアドレスが正しくありません",
        "en": "Invalid email address"
    },
    "270015": {
        "kr": "이메일 주소는 필수입니다.",
        "jp": "メールアドレスは必須です",
        "en": "Email address is required"
    },
    "280001": {
        "kr": "SwatchAI 로그인",
        "jp": "SwatchAI ログイン",
        "en": "Swatch AI Log in"
    },
    "280002": {
        "kr": "이메일 주소",
        "jp": "メールアドレス",
        "en": "Email address"
    },
    "280003": {
        "kr": "비밀번호",
        "jp": "パスワード",
        "en": "Password"
    },
    "280004": {
        "kr": "로그인",
        "jp": "ログイン",
        "en": "Log in"
    },
    "280005": {
        "kr": "비밀번호 재설정",
        "jp": "パスワードリセット",
        "en": "Reset password"
    },
    "280006": {
        "kr": "Google로 로그인",
        "jp": "Googleでサインインする",
        "en": "Sign in with Google"
    },
    "280007": {
        "kr": "이메일 주소가 잘못되었습니다.",
        "jp": "メールアドレスが不正です",
        "en": "Email address is invalid"
    },
    "280008": {
        "kr": "사용자를 찾을 수 없음",
        "jp": "ユーザーが見つかりません",
        "en": "User not found"
    },
    "280009": {
        "kr": "비밀번호가 잘못되었습니다.",
        "jp": "パスワードが間違っています",
        "en": "your password is incorrect"
    },
    "280010": {
        "kr": "비밀번호를 재설정하는 이메일을 보냈습니다.",
        "jp": "パスワードをリセットするメールを送りました",
        "en": "We have sent you an email to reset your password"
    },
    "280011": {
        "kr": "오류: 비밀번호 재설정 이메일을 보낼 수 없습니다.",
        "jp": "エラー：パスワードをリセットするメールを送信できませんでした",
        "en": "Error: Failed to send email to reset your password"
    },
    "280012": {
        "kr": "로그아웃",
        "jp": "ログアウト",
        "en": "Log out"
    },
    "290001": {
        "kr": "로그",
        "jp": "ログ",
        "en": "Log"
    },
    "290002": {
        "kr": "일시",
        "jp": "日時",
        "en": "Date and time"
    },
    "290003": {
        "kr": "종류",
        "jp": "種別",
        "en": "Type"
    },
    "290004": {
        "kr": "내용",
        "jp": "内容",
        "en": "Detail"
    }
};

export default locale;