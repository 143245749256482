import { createRouter, createWebHistory } from "vue-router";

import Login from "@/Login.vue";
import Home from "@/components/Home.vue";
import ViewDbData from "@/components/ViewDbData.vue";
import ProcureItems from "@/components/ProcureItems.vue";
import ProcessItems from "@/components/ProcessItems.vue";
import BatchProductionCloth from "@/components/BatchProductionCloth.vue";
import DyeingItems from "@/components/DyeingItems.vue";
import FinishItems from "@/components/FinishItems.vue";
import MoveItems from "@/components/MoveItems.vue";
import SalesItems from "@/components/SalesItems.vue";
import PaymentCollectItems from "@/components/PaymentCollectItems.vue";
import RegisterItems from "@/components/RegisterItems.vue";
import TraceMain from "@/components/TraceMain.vue";
import User from "@/components/User.vue";
import Log from "@/components/Log.vue";
import SampleHtml from "@/components/SampleHtml.vue";
import SampleVue from "@/components/SampleVue.vue";

export const router = new createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Login,
    },

    {
      path: "/home",
      component: Home,
    },

    //-----------------------------------------------------------------
    // データビュー
    {
      name: "viewFactoryInventory",
      path: "/viewFactoryInventory",
      component: ViewDbData,
      props: { route: "viewFactoryInventory" },
    },
    {
      name: "viewRecProcure",
      path: "/viewRecProcure",
      component: ViewDbData,
      props: { route: "viewRecProcure" },
    },
    {
      name: "viewRecProcess",
      path: "/viewRecProcess",
      component: ViewDbData,
      props: { route: "viewRecProcess" },
    },
    {
      name: "viewRecDetails",
      path: "/viewRecDetails",
      component: ViewDbData,
      props: { route: "viewRecDetails" },
    },
    {
      name: "viewRecMove",
      path: "/viewRecMove",
      component: ViewDbData,
      props: { route: "viewRecMove" },
    },
    {
      name: "viewRecPaymentCollect",
      path: "/viewRecPaymentCollect",
      component: ViewDbData,
      props: { route: "viewRecPaymentCollect" },
    },
    {
      name: "viewRecSales",
      path: "/viewRecSales",
      component: ViewDbData,
      props: { route: "viewRecSales" },
    },
    {
      name: "viewSupplierThread",
      path: "/viewSupplierThread",
      component: ViewDbData,
      props: { route: "viewSupplierThread" },
    },
    {
      name: "viewSupplierCloth",
      path: "/viewSupplierCloth",
      component: ViewDbData,
      props: { route: "viewSupplierCloth" },
    },
    {
      name: "viewCustomer",
      path: "/viewCustomer",
      component: ViewDbData,
      props: { route: "viewCustomer" },
    },
    {
      name: "viewFactory",
      path: "/viewFactory",
      component: ViewDbData,
      props: { route: "viewFactory" },
    },
    {
      name: "viewThread",
      path: "/viewThread",
      component: ViewDbData,
      props: { route: "viewThread" },
    },
    {
      name: "viewCloth",
      path: "/viewCloth",
      component: ViewDbData,
      props: { route: "viewCloth" },
    },
    {
      name: "viewColor",
      path: "/viewColor",
      component: ViewDbData,
      props: { route: "viewColor" },
    },
    {
      name: "viewProduct",
      path: "/viewProduct",
      component: ViewDbData,
      props: { route: "viewProduct" },
    },

    //-----------------------------------------------------------------
    // 購入 糸
    {
      name: "procureThread",
      path: "/procureThread",
      component: ProcureItems,
      props: { route: "procureThread" },
    },
    // 購入 布
    {
      name: "procureCloth",
      path: "/procureCloth",
      component: ProcureItems,
      props: { route: "procureCloth" },
    },

    //-----------------------------------------------------------------
    // 生産
    {
      name: "startProductionCloth",
      path: "/startProductionCloth",
      component: ProcessItems,
      props: { route: "startProductionCloth" },
    },
    {
      name: "finishProductionCloth",
      path: "/finishProductionCloth",
      component: FinishItems,
      props: { route: "finishProductionCloth" },
    },
    {
      name: "startDyeingCloth",
      path: "/startDyeingCloth",
      component: DyeingItems,
      props: { route: "startDyeingCloth" },
    },
    {
      name: "finishDyeingCloth",
      path: "/finishDyeingCloth",
      component: FinishItems,
      props: { route: "finishDyeingCloth" },
    },
    {
      name: "startProductionProduct",
      path: "/startProductionProduct",
      component: ProcessItems,
      props: { route: "startProductionProduct" },
    },
    {
      name: "finishProductionProduct",
      path: "/finishProductionProduct",
      component: FinishItems,
      props: { route: "finishProductionProduct" },
    },

    //-----------------------------------------------------------------
    // バッチ処理（生産、生産完了、移動）
    {
      name: "batchProductionCloth",
      path: "/batchProductionCloth",
      component: BatchProductionCloth,
      props: { route: "batchProductionCloth" },
    },

    //-----------------------------------------------------------------
    // 移動
    {
      name: "moveThread",
      path: "/moveThread",
      component: MoveItems,
      props: { route: "moveThread" },
    },
    {
      name: "moveCloth",
      path: "/moveCloth",
      component: MoveItems,
      props: { route: "moveCloth" },
    },
    {
      name: "moveProduct",
      path: "/moveProduct",
      component: MoveItems,
      props: { route: "moveProduct" },
    },

    //-----------------------------------------------------------------
    // 販売
    {
      name: "salesProduct",
      path: "/salesProduct",
      component: SalesItems,
      props: { route: "salesProduct" },
    },

    //-----------------------------------------------------------------
    // 支払い・回収
    {
      name: "paymentThread",
      path: "/paymentThread",
      component: PaymentCollectItems,
      props: { route: "paymentThread" },
    },
    {
      name: "paymentCloth",
      path: "/paymentCloth",
      component: PaymentCollectItems,
      props: { route: "paymentCloth" },
    },
    {
      name: "collectReceivable",
      path: "/collectReceivable",
      component: PaymentCollectItems,
      props: { route: "collectReceivable" },
    },

    //-----------------------------------------------------------------
    // 登録
    {
      name: "registerThread",
      path: "/registerThread",
      component: RegisterItems,
    },
    {
      name: "registerCloth",
      path: "/registerCloth",
      component: RegisterItems,
    },
    {
      name: "registerColor",
      path: "/registerColor",
      component: RegisterItems,
    },
    {
      name: "registerProduct",
      path: "/registerProduct",
      component: RegisterItems,
    },
    {
      name: "registerFactory",
      path: "/registerFactory",
      component: RegisterItems,
    },
    {
      name: "registerSupplierThread",
      path: "/registerSupplierThread",
      component: RegisterItems,
    },
    {
      name: "registerSupplierCloth",
      path: "/registerSupplierCloth",
      component: RegisterItems,
    },
    {
      name: "registerCustomer",
      path: "/registerCustomer",
      component: RegisterItems,
    },

    //-----------------------------------------------------------------
    // その他
    {
      name: "trace",
      path: "/trace",
      component: TraceMain,
      props: { route: "trace" },
    },
    {
      name: "user",
      path: "/user",
      component: User,
      props: { route: "user" },
    },
    {
      name: "log",
      path: "/log",
      component: Log,
      props: { route: "log" },
    },
    {
      name: "sampleHtml",
      path: "/sampleHtml",
      component: SampleHtml,
      props: { route: "sampleHtml" },
    },
    {
      name: "sampleVue",
      path: "/sampleVue",
      component: SampleVue,
      props: { route: "sampleVue" },
    },
  ],
});

export default router;
